import { ButtonCust, TextFieldCust } from "../widgets";
import { ForgotPasswordProps } from ".";
import EnvConfig from "../../config/env.config.json";
import {
  Box,
  Divider,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const ForgotPasswordT1 = (props: ForgotPasswordProps) => {
  const theme = useTheme();

  const {
    email,
    emailError,
    onChangeHandler,
    handleToggle,
    handleForgotPassword,
    isForgotClicked,
    content,
  } = props;

  const {
    heading_forgot_pwd,
    email_label_forgot,
    btn_forgot,
    return_text1,
    return_text2,
    forgot_pwd_logo,
    heading_fp_logo,
  } = content;
  return (
    <Box>
      <Typography
        component={"h5"}
        variant={"h5"}
        fontSize="20px"
        fontFamily={"var(--font_family_Bold)"}
        pt={{ xs: "25px", sm: 0 }}
        pb={{ xs: "25px", sm: "15px" }}
        textAlign={{ xs: "center", sm: "unset" }}
        fontWeight={"var(--font_weight_4)"}
        display={"flex"}
        justifyContent={{ xs: "flex-start", sm: "unset" }}
        alignItems="center"
      >
        {heading_fp_logo?.data?.attributes?.url && (
          <Box
            component="img"
            alt={heading_fp_logo?.data?.attributes?.alternativeText}
            src={heading_fp_logo?.data?.attributes?.url}
            width="23px"
            height="30px"
            mr="10px"
          />
        )}
        {heading_forgot_pwd}
      </Typography>

      <Divider
        sx={{
          bgcolor: "var(--ford-grey-color)",
          opacity: "0.5",
          marginBottom: "20px",
        }}
      />

      <Container disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}>
        <TextFieldCust
          value={email}
          label={email_label_forgot}
          name="email"
          onChange={onChangeHandler}
          error={emailError !== "" ? true : false}
          helperText={emailError ? emailError : ""}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          <ButtonCust
            sx={{ my: "25px" }}
            onClick={handleForgotPassword}
            loading={isForgotClicked}
            variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
          >
            {btn_forgot}
          </ButtonCust>
        </Box>
        <Typography
          component={"div"}
          fontSize={"14px"}
          fontFamily={"var(--font_family_Semibold)"}
          pb="25px"
          sx={{
            textAlign: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          {return_text1}&nbsp;
          <Typography
            component={"span"}
            fontSize={"inherit"}
            color={"var(--primary_color)"}
            onClick={handleToggle}
            fontFamily={"inherit"}
            fontWeight="var(--font_weight_2)"
            sx={{ cursor: "pointer" }}
          >
            {return_text2}
          </Typography>
        </Typography>
      </Container>

      {forgot_pwd_logo?.data?.attributes?.url && (
        <Box
          component="img"
          width={"250px"}
          height="200px"
          alt={forgot_pwd_logo?.data?.attributes?.alternativeText}
          src={forgot_pwd_logo?.data?.attributes?.url}
          display="flex"
          margin="auto"
        />
      )}
    </Box>
  );
};

export default ForgotPasswordT1;
