import { Box, Divider, Grid, Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import {
  generateImageContainer,
  getResolutionDetails,
} from "../../../utils/commonFunctions";
import styles from "./S4_Footer.module.scss";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { scroller } from "react-scroll";
import { LazyLoadImageCust } from "../../widgets";
import ENVConfig from "../../../config/env.config.json";

type Props = {
  isFixed?: boolean;
  customerDetail: any;
  footerContent: any;
  onClickHandler: any;
};

const FooterComponent = (props: Props) => {
  const location = useLocation();
  const { customerDetail, footerContent, onClickHandler } = props;
  const {
    footer_t2,
    links_grid,
    links,
    sub_links,
    disclaimer_text,
    copyright_text,
    version_indicator,
    new_tag,
    link_item,
  } = styles;

  const {
    footerListDetails,
    copyright,
    disclaimer,
    logo_desktop,
    logo_tab,
    logo_mobile,
    showDisclaimer,
    newText,
    showVersion,
    socialMediaIons,
    androidMobileStore,
    iosMobileStore,
  } = footerContent || {};

  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  const handleImageClick = (path: string | URL | undefined)=>() => {
    window.open(path, "_blank");
  };
  const footerList = (val: any, index: number) => {
    const returnSec = (
      <Box
        className={sub_links}
        mt={{ xs: "10px", lg: "0px" }}
        key={index}
        role="button"
      >
        {val.name}
        {val.isNew && (
          <span className={`${new_tag} px-1 mx-1 rounded-1`}>{newText}</span>
        )}
      </Box>
    );
    const resultSet = (
      <>
        {val.isLink ? (
          <Link
            onClick={() =>
              onClickHandler(
                BrandNameUpdate(val.navigationPath),
                val.redirectionPage,
                val.gaName,
                val.is_gaEventReqd
              )
            }
            underline="none"
          >
            {returnSec}
          </Link>
        ) : (
          <Link
            onClick={() => {
              if (val?.keyProp === 1) {
                onClickHandler(
                  BrandNameUpdate(val.navigationPath),
                  val?.redirectionPage,
                  val?.gaName,
                  val?.is_gaEventReqd
                );
                setTimeout(function () {
                  scroller.scrollTo("bestCellPhonePlan", {
                    duration: 1000,
                    delay: 0,
                    offset: -100,
                    smooth: true,
                  });
                }, 1000);
              } else {
                if (
                  val &&
                  val.name &&
                  typeof val.name === "string" &&
                  val.name.toLowerCase() === "hearing aid compatibility policy"
                ) {
                  shopwareHandler(
                    ENVConfig?.SHOP_DOMAIN,
                    "hearing-aid-compatibility"
                  );
                } else if (
                  val &&
                  val.name &&
                  typeof val.name === "string" &&
                  val.name.toLowerCase() ===
                    "return and warranty policy for new device"
                ) {
                  shopwareHandler(
                    ENVConfig?.SHOP_DOMAIN,
                    "warranty-returns-policy"
                  );
                } else if (
                  val &&
                  val.name &&
                  typeof val.name === "string" &&
                  val.name.toLowerCase() ===
                    "return and warranty policy for refurbished device"
                ) {
                  shopwareHandler(
                    ENVConfig?.SHOP_DOMAIN,
                    "warranty-returns-refurbished"
                  );
                } else {
                  onClickHandler(
                    BrandNameUpdate(val.navigationPath),
                    val.redirectionPage,
                    val.gaName,
                    val?.is_gaEventReqd
                  );
                }
              }
            }}
            rel="noreferrer"
            underline="none"
          >
            {returnSec}
          </Link>
        )}
      </>
    );
    const shopwareHandler = (navigationPath: string, endPoint: string) => {
      let swContextToken: any = Cookies.get("swContextToken");
      let idTokenValue: any = localStorage.getItem("accessToken");
      let currentuser: any = localStorage.getItem("currentuser");
      currentuser = JSON.parse(currentuser);
      window.open(
        `${navigationPath}/${endPoint}/?token=${swContextToken}&firebase_token=${idTokenValue}&logged_in=${
          currentuser && currentuser.email ? true : false
        }`,
        "_blank"
      );
    };
    return (
      <>
        <Box
          className={link_item}
          sx={{ padding: { md: "0px 0px 24px 48px" } }}
          key={val?.keyProp}
        >
          {resultSet}
        </Box>
      </>
    );
  };
  const currentYear = new Date().getFullYear();

  return location.pathname === RoutingURLs?.checkout ? (
    <></>
  ) : (
    <Grid
      container
      display={{ xs: "flex" }}
      flexDirection={{ xs: "column" }}
      position="static"
      className={footer_t2}
    >
      <Grid
        container
        item
        p={{ xs: "30px", sm: "50px 25px 25px 25px", md: "50px 50px 10px 50px" }}
        className={links_grid}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
          sm={5}
          lg={2.5}
          display="flex"
          justifyContent="flex-start"
          sx={{ alignItems: { xs: "center", md: "unset" } }}
          className="footer_logo_alignment"
          flexDirection="column"
        >
          <Box>
            {(largeDesktop || desktop) &&
              generateImageContainer({
                data: logo_desktop,
              })}
            {ipad &&
              generateImageContainer({
                data: logo_tab,
              })}
            {mobile &&
              generateImageContainer({
                data: logo_mobile,
              })}
          </Box>
          <Box display="flex" pt={"15px"} pb={"5px"}>
            {socialMediaIons?.map((val: any, ind: number) => {
              return (
                <Box
                  mr={1}
                  key={ind}
                  onClick={() =>
                    onClickHandler(
                      val.navigationPath,
                      val.redirectionPage,
                      val.name
                    )
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <Box>
                    <LazyLoadImageCust
                      src={val?.icon?.data?.attributes?.url || ""}
                      alt={
                        BrandNameUpdate(
                          val?.icon?.data?.attributes?.alternativeText
                        ) || ""
                      }
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          lg={9.5}
          className={links}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={{
            xs: "space-between",
            sm: "flex-start",
            md: "flex-start",
          }}
          flexWrap="wrap"
        >
          {footerListDetails?.map((val: any, index: number) => {
            if (index === 0) {
              return customerDetail?.simPaid ? null : footerList(val, index);
            } else return footerList(val, index);
          })}
        </Grid>
      </Grid>
      <Divider />
      <Box sx={{display: "flex", justifyContent: "center"}}>
      <Grid item className={copyright_text}>
        {BrandNameUpdate(copyright)?.replace("{year}", String(currentYear))}
      </Grid>
      <Box sx={{
        marginTop: "6px",
        marginLeft: "auto",
        marginRight: "20px"
      }}>
        <Box
          component="img"
          alt={androidMobileStore?.img?.data?.attributes?.alternativeText || ""}
          height="34px"
          width="100px"
          src={androidMobileStore?.img?.data?.attributes?.url || ""}
          onClick={handleImageClick(androidMobileStore?.navigationPath)}
          sx={{
            cursor: "pointer",
            marginRight: "10px"
          }}
        />
      <Box
        component="img"
        alt={iosMobileStore?.img?.data?.attributes?.alternativeText || ""}
        height="34px"
        width="100px"
        src={iosMobileStore?.img?.data?.attributes?.url || ""}
        onClick={handleImageClick(iosMobileStore?.navigationPath)}
        sx={{
          cursor: "pointer"
        }}
      />
      </Box>
      </Box>
    </Grid>
  );
};

export default FooterComponent;
