import { useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "./S1_ActivationStep1.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IMEIFailureModal } from "./IMEIFailureModal";
import {
  TextFieldCust,
  LinearProgressBarCust,
  ButtonCust,
  ManageCard,
} from "../../../widgets";
import { gaCategory } from "../../../../Types/GoogleAnalytics";
import OneTimeCharge from "./OneTimeCharge";
import OneTimeChargeComp from "./OneTimeChargeComp";
import { AddShippingAddressDialog } from "../../Account/AddLinesDialog/AddShippingAddressDialog/AddShippingAddressDialog";
import { ChangeLinePreferenceDialog } from "../../../ChangeLinePreferenceDialog/ChangeLinePreferenceDialog";
import { SimTypes } from "../../../../features/checkout/Types";
import Config from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import mobileImage from "../../../../assets/Common/mobileImage.svg";
import flightlv from "../../../../assets/Common/flightlv.svg";
import { InternalBanner } from "../../../common";
import { AddShippingAddressDialogComp } from "../../Account/AddLinesDialog/AddShippingAddressDialog/AddShippingAddressDialogComp";
import { ActivationStatus, orderType, SimTypeEnums } from "../../../../enums";
import { shipmentOrderNew } from "../../../../features/trackorder/trackorderSlice";
import { UpdateCustomerSource } from "../../../../features/checkout/checkoutSlice";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";

const ActivationStep1 = (props: any) => {
  const {
    handleStep,
    currentLine,
    numChangeHandler,
    content,
    simPrefenceContent,
    GAAndFBEventsHandler,
    kitBasePrice,
    sendSimOption,
    deviceComp,
    setDeviceComp,
    currentModal,
    setCurrentModal,
    formik,
    btnLoader,
    setBtnLoader,
    modalStatus,
    setModalStatus,
    isEsimAvailable,
    imei,
  } = props;
  const provideEdit: boolean = currentLine?.esim ? true : false;
  const onTimeActivation = localStorage.getItem("oneTimeactivation");
  const getLatestAdd: any = JSON?.parse(
    localStorage.getItem("latestAddress") || "null"
  );
  const [addressData, setAddressData] = useState<any>(() => getLatestAdd);
  const [simType, setSimType] = useState<SimTypes>(SimTypes.sim);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [showImeiScreen, setShowImeiScreen] = useState<any>(true);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});
  const { esimActivationTax } = useAppSelector((state: any) => state.checkout);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    imei_view,
    Iemi_con,
    imei_form,
    sp1_title,
    step_txt,
    msgs,
    verfiy_details_txt,
  } = content || {};

  const changeModal = (val?: string) => {
    setCurrentModal(val ?? "");
  };
  const closeOneTimeCharge = () => {
    changeModal();
    setAddressData(null);
    localStorage.removeItem("latestAddress");
    onTimeActivation && localStorage.removeItem("oneTimeactivation");
    localStorage.removeItem("oneTimeChargeDeliveryType");
  };
  const showCardChangeModalHandler = () => {
    changeModal("oneTimeChrg");
  };
  const ProceedPayment = () => {};
  const changeCard = (obj?: any) => {
    if (obj) {
      localStorage.setItem("latestAddress", JSON.stringify(obj));
      setAddressData(obj);
    }
    changeModal("cardsModal");
  };
  const changeAddress = (obj?: any) => {
    obj && setAddressData(obj);
    changeModal("addressDetails");
  };
  const handleNavigateNext = (type: any) => {
    changeModal(type);
  };
  const SaveAddress = (e: any) => {
    changeModal("oneTimeChrg");
    e?.billingAddress && setAddressData(e.billingAddress);
  };
  const chooseModalResult = () => {
    const currentLineSimType = currentLine.esim ? SimTypes.eSim : SimTypes.sim;
    if (currentLineSimType === simType) {
      handleStep(true);
      changeModal();
      return;
    }
    if (simType === SimTypes.sim) {
      changeModal("oneTimeChrg");
      setShowImeiScreen(false);
      return;
    }
    if (simType === SimTypes.eSim) {
      shipmentReorder();
      // changeModal("esimCnfrmEmail");
      // setShowImeiScreen(false);
      setBtnLoader(true);
      // return;
    }
  };
  let customerDetails: any = localStorage.getItem("customerDetail");
  customerDetails = customerDetails ? JSON.parse(customerDetails) : null;

  const shipmentReorder = () => {
    const currentCard = getCCDetails?.filter(
      (val: any) => val?.isAutoPay === true
    );
    let estimatedDeliveryDate = new Date();
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 4);
    const esimPayload: any = {
      cId: customerDetails.id,
      reachCarrierId: sendSimOption[0]?.reachCarrierId,
      deliveryDays: 0,
      serviceType: "email",
      carrierCode: sendSimOption[0]?.carrierCode,
      serviceCode: "email",
      shippingAmount: esimActivationTax,
      shipmentType: SimTypeEnums.eSim.toUpperCase(),
      category: SimTypeEnums.sim.toUpperCase(),
      estimatedDeliveryDate: estimatedDeliveryDate,
      secondaryCustomers: [currentLine.id],
      taxAmount: 0,
      taxId: "",
      taxInfo: { totalTax: 0 },
      totalAmount: 0,
      ccUUID: currentCard?.[0]?.uuid,
      shipTo: {
        city: customerDetails?.addresses?.[0]?.city,
        country: customerDetails?.addresses?.[0]?.country,
        name: customerDetails?.addresses?.[0]?.name,
        phone: customerDetails?.addresses?.[0]?.phone,
        postalCode: customerDetails?.addresses?.[0]?.zip,
        state: customerDetails?.addresses?.[0]?.state,
        street1: customerDetails?.addresses?.[0]?.address1,
        residential: "false",
      },
      items: [
        {
          quantity: 1,
          unitPrice: 0,
        },
      ],
      orderType: orderType.RE_ORDER,
      shipmentReason: orderType.CustomerRequest,
    };
    setBtnLoader(true);
    dispatch(shipmentOrderNew(esimPayload)).then((res: any) => {
      if (res?.payload?.status === 200) {
        setBtnLoader(false);
        const py = {
          activationJourneyStatus: ActivationStatus.IN_PROGRESS,
          esim: true,
          eSimsOrdered: 1,
          simType: SimTypes.eSim.toUpperCase(),
        };
        dispatch(
          UpdateCustomerSource({
            customerId: currentLine.id,
            data: py,
          })
        );
        navigate(RoutingURLs.activateEsim);
      } else {
        setErrorMsg(true);
      }
      setBtnLoader(false);
    });
  };
  //   <ArrowBackIcon
  //   sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
  //   className={styles.arrow_icon}
  //   onClick={() => handleStep(false)}
  // />

  return (
    <>
      {/* <InternalBanner
        // key={key}
        pageName={"Activation"}
        apiEndPoint={"myAccBanner"}
        type={"internal"}
      /> */}
      <Box
        sx={{
          cursor: "pointer",
          width: "18px",
          height: "18px",
          zIndex: "100",
          left: "0",
          position: "absolute",
          ml: { md: "30px", sm: "25px", xs: "25px" },
          mt: { md: "60px" },
          display: { xs: "none", sm: "inline-block" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "18px",
            height: "18px",
            position: "absolute",
            left: "3rem",
          }}
          onClick={() => handleStep(false)}
        >
          <ArrowBackIosIcon sx={{ width: "18px" }} />
          <Box>Back</Box>
        </Box>
      </Box>
      <KeyboardBackspaceIcon
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          left: "1.4rem",
          top: "6.1rem",
          color: "var(--white)",
          cursor: "pointer",
        }}
        onClick={() => handleStep(false)}
      />
      {currentModal !== "oneTimeChrg" &&
        currentModal !== "addressDetails" &&
        currentModal !== "esimCnfrmEmail" && (
          <>
            <Box className={styles.step1_main}>
              {!deviceComp && showImeiScreen ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      background: "var(--lightgrey_3)",
                    }}
                  >
                    <Paper
                      sx={{
                        // border: "1px solid rgba(0,0,0,.2)",
                        width: { xs: "94%", sm: "724px" },
                        // height: "600px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        mt: { xs: "60px" },
                        boxShadow: { xs: "unset !important" },
                        marginBottom: "45px",
                        paddingBottom: "40px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          pt: { xs: "31px" },
                          pb: { xs: "22px" },
                        }}
                      >
                        <Box
                          component="img"
                          alt={"circular_loader"}
                          src={
                            content?.imei_comp_headIcon?.data?.attributes?.url
                          }
                          sx={{ width: { xs: "33px", paddingRight: "16px" } }}
                        />
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontFamily: "var(--font_family_Bold)",
                          }}
                        >
                          {sp1_title}
                        </Typography>
                      </Box>
                      <hr
                        style={{ border: "1px solid #F8F8F8", width: "100%" }}
                      ></hr>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "var(--font_family_Semibold)",
                            color: "var(--text_color)",
                            mt: { xs: "18px" },
                            mb: { xs: "21px" },
                          }}
                        >
                          {step_txt}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "var(--font_family_Regular)",
                            color: "var(--text_color)",
                            mt: { xs: "18px" },
                            mb: { xs: "21px" },
                          }}
                        >
                          {BrandNameUpdate(imei_form?.desc)}
                        </Typography>
                      </Box>
                      <Box
                        width={{ xs: "80%", sm: "80%", md: "70%" }}
                        sx={{ px: { md: "12px" } }}
                      >
                        <TextFieldCust
                          value={formik.values.imei}
                          name="imei"
                          onChange={(e: any) => numChangeHandler(e, formik)}
                          onBlur={formik.handleBlur}
                          type={"text"}
                          label={imei_form?.txt_ph}
                          maxlength={15}
                          error={formik.errors?.imei ? true : false}
                          helperText={formik.errors?.imei}
                          onKeyUp={(e: any) => {
                            if (e.key === "Enter") {
                              formik.handleSubmit();
                            }
                          }}
                        />
                        <Box display="flex" justifyContent="end">
                          <Typography
                            sx={{
                              fontFamily: "var(--font_family_Regular)",
                              color: "var(--text_color)",
                              fontSize: "12px",
                              marginTop: "2px",
                            }}
                            className={styles.activation_limit_div}
                          >
                            {formik.values.imei.length}/15
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          fontFamily: "var(--font_family_Regular)",
                          color: "var(--text_color)",
                          marginBottom: "25px",
                          textAlign: { xs: "center" },
                          width: { xs: "80%" },
                        }}
                        component="p"
                      >
                        {imei_form?.note}
                      </Box>
                      <Box sx={{ marginBottom: "20px" }}>
                        <ButtonCust
                          variantType={Config.PRIMARY_BUTTON_TYPE}
                          sx={{
                            width: { xs: "250px", sm: "250px", md: "250px" },
                            height: { md: "50px !important" },
                          }}
                          disabled={
                            formik.values?.imei.length === 0
                              ? true
                              : !formik?.isValid
                          }
                          onClick={() => formik.handleSubmit()}
                          loading={btnLoader}
                        >
                          {imei_form?.check_btn}
                        </ButtonCust>
                      </Box>
                    </Paper>
                  </Box>
                  <Box
                    className={styles.activation_bottom}
                    sx={{ background: "var(--lightgrey_3)" }}
                  >
                    <Box>
                      <Typography
                        className={styles.activation_bottom_text1}
                        sx={{
                          mb: "5px",
                          fontFamily: " var(--font_family_Medium)",
                        }}
                      >
                        {Iemi_con?.title}
                      </Typography>
                      <Typography
                        className={styles.activation_bottom_text1}
                        sx={{ fontFamily: " var(--font_family_Medium)" }}
                      >
                        {Iemi_con?.desc}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        margin: "20px 0 50px 0",
                      }}
                      width={{ xs: "90%", sm: "fit-content" }}
                    >
                      {Iemi_con?.iemiContent.map((data: any) => {
                        return (
                          <Box
                            className={styles.activation_version_container}
                            mt={"20px"}
                            key={data.desc1}
                          >
                            <img
                              width={23}
                              height={23}
                              className={styles.os_img}
                              src={data.img?.data?.attributes?.url}
                              alt={data.img?.data?.attributes?.alternativeText}
                            />
                            <Box>
                              <Typography className={styles.os_text_1}>
                                {data.desc1}
                              </Typography>
                              <Typography className={styles.os_text_2}>
                                {data.desc2}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </>
              ) : showImeiScreen ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    background: "var(--lightgrey_3)",
                    height: "fit-content",
                    minHeight: "88vh",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Paper
                    sx={{
                      width: { xs: "94%", sm: "801px" },
                      height: { xs: "100%", md: "400px" },
                      mt: { xs: "30px", sm: "60px" },
                      mx: { xs: "6px" },
                      boxShadow: { xs: "unset !important" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pt: { xs: "25px", sm: "31px" },
                        pb: { xs: "16px", sm: "22px" },
                      }}
                    >
                      <Box
                        component="img"
                        alt={"circular_loader"}
                        src={content?.mobile_icon_main?.data?.attributes?.url}
                        sx={{
                          width: {
                            xs: "20px",
                          },
                          pr: { xs: "16px", sm: "16px" },
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: { xs: "20px", sm: "24px" },
                          fontFamily: "var(--font_family_Bold)",
                        }}
                      >
                        {verfiy_details_txt}
                      </Typography>
                    </Box>
                    <hr style={{ border: "1px solid #F8F8F8" }}></hr>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontFamily: "var(--font_family_Medium)",
                          mt: { xs: "18px" },
                          mb: { xs: "21px" },
                        }}
                      >
                        {step_txt}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        justifyContent: "center",
                        mx: "68px",
                        gap: { xs: "20px", md: 0 },
                      }}
                    >
                      <Box sx={{ display: { xs: "flex" } }}>
                        <Box
                          component="img"
                          alt={"circular_loader"}
                          src={content?.mobile_icon_imei?.data?.attributes?.url}
                          sx={{ width: { xs: "20px" }, mr: "20px" }}
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="baseline"
                          sx={{
                            mr: "70px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "var(--font_family_Medium)",
                              color: "var(--text_color)",
                            }}
                          >
                            {imei_view?.deviceID}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "var(--font_family_Semibold)",
                            }}
                          >
                            {currentLine?.imei}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: { xs: "flex" } }}>
                        <Box
                          component="img"
                          alt={"circular_loader"}
                          src={
                            content?.mobile_icon_device?.data?.attributes?.url
                          }
                          sx={{ width: { xs: "20px" }, mr: "20px" }}
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="baseline"
                          sx={{ mr: "70px" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "var(--font_family_Medium)",
                              color: "var(--text_color)",
                            }}
                          >
                            {imei_view?.device}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "var(--font_family_Semibold)",
                            }}
                          >
                            {currentLine?.model}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: { xs: "flex" } }}>
                        <Box
                          component="img"
                          alt={"circular_loader"}
                          src={
                            content?.mobile_icon_simtype?.data?.attributes?.url
                          }
                          sx={{ width: { xs: "20px" }, mr: "20px" }}
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="baseline"
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "var(--font_family_Medium)",
                              color: "var(--text_color)",
                            }}
                          >
                            {imei_view?.imei_id}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "var(--font_family_Semibold)",
                            }}
                          >
                            {currentLine?.esim
                              ? content?.esim_txt
                              : content?.psim_txt}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        mt: "25px",
                        mb: { xs: "20px", sm: "36px" },
                      }}
                    >
                      <ButtonCust
                        variantType={Config.PRIMARY_BUTTON_TYPE}
                        onClick={() => {
                          handleStep(true),
                            GAAndFBEventsHandler(
                              gaCategory.activation,
                              imei_view?.nxt_btn
                            );
                        }}
                        sx={{
                          width: { xs: "250px", sm: "250px", md: "250px" },
                          height: { md: "50px !important" },
                        }}
                      >
                        {imei_view?.nxt_btn}
                      </ButtonCust>
                    </Box>
                    <Box
                      onClick={() => {
                        setDeviceComp(false);
                        GAAndFBEventsHandler(
                          gaCategory.activation,
                          imei_view?.edd_txt
                        );
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "var(--primary_color)",
                          fontSize: "16px",
                          fontFamily: "var(--font_family_Semibold)",
                          cursor: "pointer",
                          mb: "2rem",
                        }}
                      >
                        {content?.edit_phone_txt}
                      </Typography>
                    </Box>
                  </Paper>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ m: "30px auto 30px auto" }}
                    width={{ xs: "90%", sm: "46%" }}
                    alignItems={"baseline"}
                  >
                    <Box component="span">{content?.Note_txt}&nbsp;</Box>
                    <Box component="p" textAlign={{ xs: "left", sm: "center" }}>
                      <Box
                        component="span"
                        sx={{
                          fontFamily: "var(--font_family_Regular)",
                          fontSize: "16px",
                        }}
                      >
                        {content?.note_desc}&nbsp;
                      </Box>
                      <Box
                        component="a"
                        sx={{
                          color: "var(--primary_color)",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        href={content?.psim_clickhere_link}
                        target="_blank"
                      >
                        {content?.click_here_txt}
                      </Box>
                      .
                    </Box>
                  </Box>
                </Box>
              ) : null}
              {modalStatus && (
                <IMEIFailureModal
                  modalFg={modalStatus}
                  closeHandler={() => setModalStatus(false)}
                  content={content}
                />
              )}
              {currentModal === "cardsModal" && (
                <ManageCard
                  cardData={getCCDetails}
                  showCardChangeModal={true}
                  setShowCardChangeModal={showCardChangeModalHandler}
                  isModalRequired={true}
                  pageName={"activation"}
                  isDescription={false}
                  payType={false}
                  ProceedPayment={ProceedPayment}
                  isPayConfirm={true}
                  setSelectedCard={setSelectedCard}
                  activationOptional={true}
                />
              )}

              {currentModal === "chooseType" && (
                <ChangeLinePreferenceDialog
                  open={true}
                  onClose={closeOneTimeCharge}
                  content={simPrefenceContent}
                  onConfirm={chooseModalResult}
                  simType={simType}
                  isEsimAvailable={isEsimAvailable}
                  setSimType={setSimType}
                  pageName={"activation"}
                  simCost={
                    kitBasePrice +
                    (currentLine?.selectedShippingMethod?.shippingAmount || 0)
                  }
                  imei={imei}
                  isApnNote={true}
                  currentLine={currentLine}
                  btnLoader={btnLoader}
                  errorMsg={errorMsg}
                />
              )}
            </Box>
          </>
        )}
      {(currentModal === "oneTimeChrg" ||
        currentModal === "esimCnfrmEmail") && (
        <OneTimeChargeComp
          //   onClose={closeOneTimeCharge}
          changeCard={changeCard}
          changeAddress={changeAddress}
          latestAddress={addressData}
          loaderHandler={setBtnLoader}
          btnLoader={btnLoader}
          currentLine={currentLine}
          content={content}
          sendSimOptions={sendSimOption}
          kitBasePrice={kitBasePrice}
          handleNavigateNext={handleNavigateNext}
          currentModal={currentModal}
          shipmentReorder={shipmentReorder}
        />
      )}
      {currentModal === "addressDetails" && (
        <AddShippingAddressDialogComp
          open={true}
          onClose={SaveAddress}
          content={content}
          updateAddress={(e: any) => {
            SaveAddress(e);
          }}
          pageName="activation"
        />
      )}
    </>
  );
};
export default ActivationStep1;
