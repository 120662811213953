import PlacesAutocomplete from "react-places-autocomplete";
import styles from "./AutoComplete.module.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { TextFieldCust } from "../../widgets";
import { Container } from "@mui/material";
type iprops = {
  value: string;
  onChange?: (val: any) => void;
  onSelect: (val: any) => void;
  onChangeVal?: () => void;
  placeholder?: string;
  inputTitle?: string;
  className?: string;
  InputLabelProps?: any;
  disabled?: any;
  error?: boolean;
  helperText?: string;
  onBlurAuto?: any;
  autoComplete?: string;
  inputRef?: any;
  name?: string;
};

export const AutoComplete = (props: iprops) => {
  return (
    <PlacesAutocomplete
      value={props.value}
      onChange={props.onChange}
      onSelect={props.onSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <Container
          sx={{ position: "relative", mb: 0, mx: 0 }}
          style={{ padding: "0px" }}
          onBlur={props.onBlurAuto}
        >
          <TextFieldCust
            label={props.placeholder}
            {...getInputProps({
              className: props.className ? props.className : "input1",
            })}
            error={props.error}
            helperText={props.helperText}
            inputRef={props?.inputRef}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
            name={props.name}
          />

          <Container
            style={{ padding: "0px" }}
            className={styles.autocomplete_dropdown_container}
          >
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? `suggestion-item--active ${styles.autocomplete_dropdown}`
                : `suggestion-item ${styles.autocomplete_dropdown}`;
              if (
                suggestion &&
                suggestion.terms &&
                suggestion.terms.length > 0 &&
                suggestion.terms
                  .map((el: any) => {
                    if (
                      el.value.toUpperCase() === "USA" ||
                      el.value.toUpperCase() === "US"
                    )
                      return true;
                    else return false;
                  })
                  .filter((item: any) => item)
                  .includes(true)
              ) {
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={index}
                  >
                    <span>
                      <LocationOnIcon className={styles.location_icon} />
                    </span>
                    <span className={styles.main_text}>
                      {suggestion.formattedSuggestion &&
                        suggestion.formattedSuggestion.mainText}
                    </span>
                    <span className="SecondaryText">
                      {suggestion.formattedSuggestion &&
                        suggestion.formattedSuggestion.secondaryText}
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </Container>
        </Container>
      )}
    </PlacesAutocomplete>
  );
};
