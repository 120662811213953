import { Box, Grid, Paper, Typography } from "@mui/material";
import { ButtonCust, TextFieldCust } from "../../../widgets";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./S6_InitialActivation.module.scss";
import { ActivationStatus, CustomerStatus } from "../../../../enums";
import Config from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import { InternalBanner } from "../../../common/InternalBanner";
const T6_InitialActivation = (props: any) => {
  const { handleStep, currentLine, content, updatingCustomer } = props;
  const { extraLines } = currentLine;
  const letsGoHandler = () => {
    if (currentLine?.esim) {
      updatingCustomer({
        activationJourneyStatus: ActivationStatus.IN_PROGRESS,
      });
    }
    handleStep(true);
  };
  const {
    intro_title,
    intro_desc1,
    intro_desc11,
    intro_desc12,
    intro_desc2,
    intro_pnts,
    intro_btn,
    tick_icon,
    esim_points,
    each_icon,
    flight_icon,
  } = content || {};
  const noOfLines = extraLines + 1;
  const introPoints = currentLine?.esim ? esim_points : intro_pnts;
  const disableBack =
    currentLine?.isSimInHand &&
    currentLine?.isPrimary &&
    !Config?.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED;
  let customer: any = localStorage.getItem("customerDetail");
  customer = customer ? JSON.parse(customer) : null;

  return (
    <>
      {/* <InternalBanner
        // key={key}
        pageName={"MyAccount"}
        apiEndPoint={"myAccBanner"}
        type={"internal"}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "var(--lightgrey_3)",
          height: "fit-content",
          minHeight: "88vh",
        }}
      >
        <Box
          display={{ xs: "none", md: "block" }}
          sx={{
            cursor: disableBack ? "not-allowed !important" : "pointer",
            opacity: disableBack && "0.5",
            width: "24px",
            height: "24px",
            zIndex: "100",
            left: "0",
            position: "absolute",
            ml: { md: "30px", sm: "25px", xs: "25px" },
            mt: { md: "60px" },
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "18px",
              height: "18px",
              position: "absolute",
              left: "3rem",
            }}
            onClick={() => (disableBack ? null : handleStep(false))}
          >
            <ArrowBackIosIcon sx={{ width: "18px" }} />
            <Box>Back</Box>
          </Box>
        </Box>
        <Paper
          sx={{
            // border: "1px solid rgba(0,0,0,.2)",
            width: { xs: "90%", sm: "724px" },
            height: { xs: "450px", sm: "450px" },
            mt: { xs: "60px" },
            boxShadow: { xs: "unset !important" },
            marginBottom: "100px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: { xs: "31px" },
              pb: { xs: "22px" },
            }}
          >
            <Box
              component="img"
              alt={"circular_loader"}
              src={flight_icon?.data?.[0]?.attributes?.url}
              sx={{ width: { xs: "33px", paddingRight: "16px" } }}
            />
            <Typography
              sx={{ fontSize: "24px", fontFamily: "var(--font_family_Bold)" }}
            >
              {intro_title}
            </Typography>
          </Box>
          <hr style={{ border: "1px solid #F8F8F8" }}></hr>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "var(--font_family_Regular)",
                color: "var(--text_color)",
                mt: { xs: "18px" },
                mb: { xs: "21px" },
              }}
            >
              {intro_desc2}
            </Typography>
          </Box>
          <Box
            sx={{
              mx: currentLine?.esim ? "" : "auto",
              width: currentLine?.esim ? "auto" : "60%",
            }}
          >
            {introPoints?.map((data: any) => {
              return (
                <Box
                  className={styles.activation_info}
                  key={data.id}
                  sx={{
                    px: { xs: "30px", md: "65px" },
                    boxSizing: "border-box",
                  }}
                >
                  <img
                    className={styles.check_icon}
                    src={each_icon?.data?.[0]?.attributes?.url}
                    alt={each_icon?.data?.[0]?.attributes?.alternativeText}
                  />
                  <Typography
                    className={styles.activation_body_text}
                    sx={{
                      wordWrap: "break-word !important",
                      maxWidth: "100%",
                    }}
                  >
                    {BrandNameUpdate(data.desc)}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ textAlign: "center", mt: "25px", mb: "36px" }}>
            <ButtonCust
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={() => letsGoHandler()}
              sx={{
                width: { xs: "250px", sm: "250px", md: "250px" },
                height: { md: "50px !important" },
              }}
            >
              {intro_btn}
            </ButtonCust>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default T6_InitialActivation;

// primaryCustomer.status === "active" && localStorage.getItem("secondaryCustomer") === null
