import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Card,
  ClickAwayListener,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import unlimitedPlanDiscount from "../../../config/discountCouponMNM.json";
import EnvConfig from "../../../config/env.config.json";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { planModalType } from "../../../features/checkout/Types";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
import {
  caclulatePlanCostBasedOnEligibility,
  renderTextStyles,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { NutritionLabel } from "../../home/PlanCard/NutritionLabel/NutritionLabel";
import { ButtonCust, CustToolTip, SimpleModal } from "../../widgets";
import { AddLine_ChangePlanTemplateProps } from "./AddLine_ChangePlan";
import { getCache } from "../../../utils";
import { PlanSectionContent } from "../../../features/strapi/homePageSlice";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import styles from "../../../components/home/PlanCard/PlanCardsComponent/S13_PlanCard.module.scss";
import { updatePlanDetails } from "../../../features/checkout/checkoutSlice";
const T3_AddLine_ChangePlan = (props: AddLine_ChangePlanTemplateProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    isShowModal,
    modalType,
    setOpenModal,
    plandata,
    index,
    formik,
    handleChangePlan,
    handleAddLine,
    content,
    currentPlan,
    amendedReachPlanId,
    initialCount = 0,
    isEsim,
    currentLine,
    isAcount,
  } = props;
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    current_plan,
    fade_out,
    home_page_plan_card,
    tag,
    wrapper_div,
    mb_img,
  } = styles;
  const { heading1, heading2 } = content;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { planDetails, isLoadedPlanTaxCharges } = useAppSelector(
    (state: any) => state.checkout
  );
  const [cacheDataPlanSec, setCacheDataPlanSec] = useState<any>(null);
  const [plan, setplan] = useState<any>(null);
  const { planCardContent } = useAppSelector(
    (state: any) => state.strapi.homepage || {}
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("PlanSecHome", PlanSectionContent, setCacheDataPlanSec, dispatch);
  }, []);
  const [showAnswer, setShowAnswer] = useState<any>(true);
  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);
  const handleAnswer = () => {
    if (showAnswer) return setShowAnswer(false);
    return setShowAnswer(true);
  };
  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);

  // const {
  //   per_month_text,
  //   mbl_img,
  //   video_img,
  //   tax_text,
  //   btn_txt,
  //   data,
  //   hd_vid,
  //   sd_vid,
  //   mb_data,
  //   hotspot_img,
  //   no_hpt_fee,
  //   res,
  //   res1,
  //   res2,
  //   current_plan,
  //   broadband
  // } = cacheDataPlanSec ? cacheDataPlanSec : planCardContent;

  const [nutritionLabel, setNutritionLabel] = useState({
    show: true,
    name: "",
  });

  const selectPlanHandler = (newPlan: any) => {
    // const lines = parseInt(customerDetails.extraLines) + 1;
    if (isAcount) {
      handleChangePlan(newPlan);
    } else {
      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({
          numberOfLines: planDetails?.numberOfLines,
          plan: newPlan,
        })
      );
      localStorage.setItem(
        "reachSelectedPlanLine",
        String(planDetails?.numberOfLines)
      );
      dispatch(
        updatePlanDetails({
          numberOfLines: planDetails?.numberOfLines,
          selectedPlan: newPlan,
        })
      );
      onClose();
    }
    // onClose();
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const calculateUnlimitedCostMnM = (
    data: any,
    selectedLine: any,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;

    let planCost = 0;
    let planPerLinesCost = 0;
    if (data?.addLineSplitPrice) {
      let linePrice1 = 0;
      for (let i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data?.additionalLinePrice;
      planPerLinesCost = planCost / SELECTED_LINE;
    }
    if (isRounded) {
      planCost = Math.ceil(planCost);
      planPerLinesCost = Math.ceil(planPerLinesCost);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const calculateCost = (
    data: any,
    selectedLine: any = 1,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;
    const planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data?.additionalLinePrice;
    let planPerLinesCost = 0;
    if (isRounded) planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
    else planPerLinesCost = planCost / SELECTED_LINE;
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const calculateUnlimitedCost = (
    data: any,
    selectedLine: any = 1,
    isRounded?: boolean
  ) => {
    const SELECTED_LINE = selectedLine;

    let planCost = 0;
    let planPerLinesCost = 0;
    if (data?.addLineSplitPrice) {
      let linePrice1 = 0;
      for (let i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data?.additionalLinePrice;
      planPerLinesCost = planCost / SELECTED_LINE;
    }
    if (isRounded) {
      planCost = Math.ceil(planCost);
      planPerLinesCost = Math.ceil(planPerLinesCost);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const priceCalculationHandler: any = (data: any, selectedLine: any = 1) => {
    let PlanDiscountCost, planPerLinesCost: any;
    if (data.isUnlimited) {
      const { planCost } = calculateUnlimitedCostMnM(data, selectedLine, false);
      PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
        planCost,
        unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
      );
    } else {
      const { planCost } = calculateCost(data, selectedLine);
      PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
        planCost,
        unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
      );
    }
    planPerLinesCost = PlanDiscountCost / selectedLine;
    return planPerLinesCost;
  };

  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
        }}
      >
        <Box
          component="a"
          target="_blank"
          sx={{
            color: "var(--white)",
            fontSize: "14px",
            backgroundColor: "var(--navy)",
          }}
        >
          {data}
        </Box>
      </ClickAwayListener>
    );
  };

  return (
    <SimpleModal
      isShowModal={isShowModal}
      onCloseModal={onClose}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "100%", md: "100%", lg: "99%", xl: "85%" },
        boxShadow:
          "0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11), 0px 4px 8px 0px rgba(79, 94, 113, 0.10), 0px 8px 16px 0px rgba(79, 94, 113, 0.09), 0px 16px 32px 0px rgba(79, 94, 113, 0.08), 0px 32px 64px 0px rgba(79, 94, 113, 0.07)",
        borderRadius: "9px",
      }}
    >
      <Typography sx={renderTextStyles(1, "24px", "title-color_1", "36px")}>
        {modalType === planModalType.changePlan ? heading1 : heading2}
      </Typography>
      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          justifyContent: { xs: "flex-start", md: "space-evenly" },
          overflow: { xs: "scroll", sm: "initial" },
          flexWrap: { xs: "nowrap", sm: "wrap" },
          m: "20px auto",
          p: "4px",
          gap: { xs: "20px", sm: "0px" },
          width: "100%",
        }}
      >
        {plandata?.map((p: any, i: any) => {
          const { planCost, planPerLinesCost } = p?.unlimited
            ? calculateUnlimitedCost(p)
            : calculateCost(p);
          // const planPerLinesCost = priceCalculationHandler(p);
          return (
            <Card
              id={`home${index}`}
              key={p.displayName}
              raised
              elevation={isMobile ? 0 : 8}
              sx={{
                borderRadius: "14px",
                minWidth: "235px",
                p: "20px 10px",
                width: "15%",
                boxShadow: "none !important",
                overflow: {
                  xs: nutritionLabel.show ? "scroll" : "revert",
                  sm: nutritionLabel.show ? "scroll" : "unset",
                },
                position: {
                  xs:
                    customerDetail?.reachPlanDisplayName === p.displayName
                      ? "relative"
                      : "initial",
                  sm: "unset",
                },
                border:
                  customerDetail?.reachPlanDisplayName === p.displayName
                    ? "1px solid var(--primary_color)"
                    : "1px solid var(--plan_card_border_color)",
                height: nutritionLabel.show ? "450px" : "380px",
                maxHeight: "450px",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "&-ms-overflow-style:": {
                  display: "none",
                },
              }}
            >
              {customerDetail?.reachPlanDisplayName === p.displayName ? (
                <Box
                  sx={{
                    mt: { xs: "-8px", sm: "-32px" },
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    pb: "32px",
                    position: { xs: "absolute", sm: "unset" },
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      backgroundColor: "var(--white)",
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      top: { xs: "-20px", sm: "70px" },
                      gap: "2px",
                      px: "15px",
                      border: "1px solid var(--primary_color)",
                      py: "8px",
                      borderRadius: "10px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={
                        cacheDataPlanSec
                          ? cacheDataPlanSec?.tick_icon?.data?.attributes
                              ?.alternativeText
                          : planCardContent?.tick_icon?.data?.attributes
                              ?.alternativeText
                      }
                      src={
                        cacheDataPlanSec
                          ? cacheDataPlanSec?.tick_icon?.data?.attributes?.url
                          : planCardContent?.tick_icon?.data?.attributes?.url ||
                            ""
                      }
                      width="17px"
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { xs: "12px", sm: "14px" },
                        fontFamily: "var(--font_family_Medium)",
                        color: "#1cad45",
                        fontStyle: "italic",
                      }}
                      // sx={_.merge(
                      //   renderTextStyles(3, "14px", "#1CAD45", "21px"),
                      //   {
                      //     fontStyle: "italic",
                      //   }
                      // )}
                    >
                      {cacheDataPlanSec
                        ? cacheDataPlanSec?.current_plan
                        : planCardContent?.current_plan}
                    </Typography>
                  </Typography>
                </Box>
              ) : null}
              <Typography
                component={"h5"}
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: { xs: "20px", md: "18px" },
                  color: "var(--text_color)",
                  fontWeight: "500",
                  my: "6px",
                  textAlign: "center",
                }}
              >
                {p.displayName}
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "32px",
                    fontFamily: "var(--font_family_Bold)",
                    color: "var(--primary_color)",
                  }}
                >
                  <Typography
                    component={"div"}
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      fontSize: { xs: "20px", md: "20px" },
                      color: "var(--text_color)",
                      fontWeight: "700",
                      my: "6px",
                      textAlign: "center",
                    }}
                  >
                    {p.planData + p.dataUnit}
                    {p.planType === "LSD" ? "+" : ""}
                  </Typography>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "32px",
                        fontFamily: "var(--font_family_Bold)",
                        color: "var(--primary_color)",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "32px",
                          fontFamily: "var(--font_family_Bold)",
                          color: "inherit",
                          fontWeight: "800",
                        }}
                      >
                        $
                        {GetCurrencyFixedToTwo(
                          planCost ? planCost : 0,
                          p.currency ? p.currency : "usd",
                          true
                        )}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "10px",
                          color: "var(--text_color_12)",
                        }}
                      >
                        {cacheDataPlanSec
                          ? cacheDataPlanSec?.per_month_text
                          : planCardContent?.per_month_text}
                      </Typography>
                      <Typography
                        component={"div"}
                        sx={{
                          fontFamily: "var(--font_family_Regular)",
                          fontSize: "12px",
                          color: "var(--black)",
                          m: "4px auto 10px auto",
                        }}
                      >
                        {cacheDataPlanSec
                          ? cacheDataPlanSec?.tax_text
                          : planCardContent?.tax_text}
                      </Typography>
                    </Typography>
                  </Box>
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    // alignItems: "center",
                    pl: "25px",
                    my: "20px",
                  }}
                >
                  <Box
                    component="img"
                    alt={
                      cacheDataPlanSec
                        ? cacheDataPlanSec?.mbl_img?.data?.attributes
                            ?.alternativeText
                        : planCardContent?.mbl_img?.data?.attributes
                            ?.alternativeText
                    }
                    src={
                      cacheDataPlanSec
                        ? cacheDataPlanSec?.mbl_img?.data?.attributes?.url
                        : planCardContent?.mbl_img?.data?.attributes?.url || ""
                    }
                    width="15px"
                    className={p?.planType !== "HSD" ? mb_img : ""}
                  />
                  <Typography
                    component={"span"}
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      fontSize: "12px",
                      color: "var(--title-color)",
                      ml: "15px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        p?.planType === "HSD"
                          ? cacheDataPlanSec
                            ? cacheDataPlanSec?.res1?.buy_data
                            : planCardContent?.res1?.buy_data
                          : cacheDataPlanSec
                          ? cacheDataPlanSec?.res1?.streaming.replace(
                              /plandata/g,
                              p?.planData || ""
                            )
                          : planCardContent?.res1?.streaming.replace(
                              /plandata/g,
                              p?.planData || ""
                            ),
                    }}
                  ></Typography>
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    pl: "25px",
                    my: "20px",
                  }}
                >
                  <Box
                    component="img"
                    alt={
                      cacheDataPlanSec
                        ? cacheDataPlanSec?.hotspot_img?.data?.attributes
                            ?.alternativeText
                        : planCardContent?.hotspot_img?.data?.attributes
                            ?.alternativeText
                    }
                    src={
                      cacheDataPlanSec
                        ? cacheDataPlanSec?.hotspot_img?.data?.attributes?.url
                        : planCardContent?.hotspot_img?.data?.attributes?.url ||
                          ""
                    }
                    width="15px"
                    className={p?.planType !== "HSD" ? mb_img : ""}
                  />
                  <Typography
                    component={"span"}
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      fontSize: "13px",
                      color: "var(--title-color)",
                      ml: "15px",
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        p?.planType === "HSD"
                          ? cacheDataPlanSec
                            ? cacheDataPlanSec?.res2?.buy_data
                            : planCardContent?.res2?.buy_data
                          : cacheDataPlanSec
                          ? cacheDataPlanSec?.res2?.streaming
                          : planCardContent?.res2?.streaming,
                    }}
                  ></Typography>
                </Typography>
                {p?.planType === "HSD" ? (
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "25px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={
                        cacheDataPlanSec
                          ? cacheDataPlanSec?.mb_data?.data?.attributes
                              ?.alternativeText
                          : planCardContent?.mb_data?.data?.attributes
                              ?.alternativeText
                      }
                      src={
                        p?.planType === "HSD"
                          ? cacheDataPlanSec
                            ? cacheDataPlanSec?.mb_data?.data?.attributes?.url
                            : planCardContent?.mb_data?.data?.attributes?.url
                          : ""
                      }
                      width="15px"
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "13px",
                        color: "var(--title-color)",
                        ml: "15px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          p?.planType === "HSD"
                            ? cacheDataPlanSec
                              ? cacheDataPlanSec?.res?.buy_data
                              : planCardContent?.res?.buy_data
                            : cacheDataPlanSec
                            ? cacheDataPlanSec?.res?.streaming
                            : planCardContent?.res?.streaming,
                      }}
                    ></Typography>
                  </Typography>
                ) : null}
              </Box>
              {!currentLine?.reachPlanChangeDate &&
              amendedReachPlanId === p.name ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                      margin: "15px 0px",
                      backgroundColor: "var(--light_primary_color)",
                    }}
                  >
                    {/* <CustToolTip
                      title={PoperOver(
                        <>
                          <Typography
                            sx={{
                              fontWeight: "var(--font_weight_1)",
                              fontSize: "12px",
                              fontFamily: "var(--font_family_Medium)",
                              lineHeight: "21px",
                              "& span": {
                                fontWeight: "var(--font_weight_2)",
                                fontFamily: "var(--font_family_Semibold)",
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: amendedPlan_desc,
                            }}
                          />
                        </>
                      )}
                      open={anchorEl === data.id ? true : false}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      placement="top-start"
                      sx={{
                        width: "390px",
                        color: " var(--white) !important",

                        "& .MuiTooltip-tooltip": {
                          fontFamily: "var(--font_family_Medium) !important",
                          color: " var(--white)",
                          backgroundColor: "var(--primary_color) !important",
                          padding: " 15px !important",
                          "a > p": {
                            fontFamily: " var(--font_family_Medium) !important",
                            fontSize: "14px",
                          },
                        },

                        "& .MuiTooltip-arrow": {
                          "::before": {
                            backgroundColor: "var(--primary_color) !important",
                          },
                        },
                      }}
                      // className={tooltip_style}
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ],
                      }}
                    >
                      <Box
                        component="img"
                        alt={
                          content?.info_icon?.data?.attributes?.alternativeText
                        }
                        src={content?.info_icon?.data?.attributes?.url}
                        onMouseEnter={() => {
                          setAnchorEl(data.id);
                        }}
                        sx={{
                          width: "10px",
                          height: "10px",
                          marginRight: "10px",
                        }}
                        onMouseLeave={() => {
                          setAnchorEl(null);
                        }}
                      ></Box>
                    </CustToolTip> */}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "var(--primary_color)",

                        fontWeight: "var(--font_weight_1)",
                        textAlign: "center",
                        width: "fit-content",
                        maxWidth: "130px",
                        fontFamily: "var(--font_family_Medium)",
                      }}
                    >
                      {content?.plan_features?.new_plan_update}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: "15px",
                  }}
                >
                  <ButtonCust
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    data-testid="selectPlanHandler"
                    loading={plan?.name === p?.name && isLoadedPlanTaxCharges}
                    onClick={(e: any) => {
                      setplan(p);
                      selectPlanHandler(p);
                      let eventPrefix =
                        location.pathname === RoutingURLs.checkout
                          ? globalVal?.gtmEvents?.changeSelectPlan
                          : location.pathname === RoutingURLs.activation
                          ? isEsim
                            ? "mobile_dashboard_esim_"
                            : "mobile_dashboard_psim_"
                          : "";
                      gtmTagManager({
                        event: `${eventPrefix}${p.planData}${p.dataUnit}_select_plan`,
                        click_section: "checkout",
                      });
                    }}
                    sx={{
                      "&:disabled": {
                        backgroundColor: "var(--primary_color) !important",
                        opacity: "30%",
                        border: "none !important",
                      },
                    }}
                    disabled={
                      modalType === planModalType.changePlan &&
                      customerDetail?.reachPlanDisplayName === p.displayName
                        ? true
                        : false
                    }
                  >
                    {cacheDataPlanSec
                      ? cacheDataPlanSec?.btn_txt
                      : planCardContent?.btn_txt}
                  </ButtonCust>
                </Box>
              )}
              <Divider
                sx={{
                  borderColor: "var(--cascading-white)",
                  mb: "10px",
                  mx: { xs: "-10px", sm: "-10px" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mx: "auto",
                  gap: "2px",
                  cursor: "pointer",
                  width: "max-content",
                }}
                onClick={() => {
                  setNutritionLabel((prevState) => ({
                    ...prevState,
                    show: prevState.show ? false : true,
                    name: p?.name,
                  }));
                }}
              >
                <Typography
                  component={"span"}
                  sx={_.merge(
                    renderTextStyles(2, "14px", "primary_color", "21px"),
                    {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  )}
                >
                  {cacheDataPlanSec
                    ? cacheDataPlanSec?.broadband
                    : planCardContent?.broadband}
                </Typography>
                {nutritionLabel.show ? (
                  <KeyboardArrowUp sx={{ color: "var(--primary_color)" }} />
                ) : (
                  <KeyboardArrowDown sx={{ color: "var(--primary_color)" }} />
                )}
              </Box>
              {nutritionLabel.show ? (
                <NutritionLabel
                  {...{
                    plandata,
                    priceCalculationHandler,
                  }}
                  plandata={p}
                  selectedLine={1}
                  NLContent={
                    cacheDataPlanSec ? cacheDataPlanSec : planCardContent
                  }
                  showAnswer={showAnswer}
                  handleAnswer={handleAnswer}
                  isheading={false}
                  planCost={planCost}
                />
              ) : null}
            </Card>
          );
        })}
      </Box>
    </SimpleModal>
  );
};
export default T3_AddLine_ChangePlan;
