import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  nutritionLabelData: any;
  errorNutritionLabelData: any;
};

const initialState: InitialState = {
  nutritionLabelData: null,
  errorNutritionLabelData: null,
};

// Generates pending, fulfilled and rejected action types
export const NLContentStrapi = createAsyncThunk("NL/NLContentStrapi", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.NutritionLabelPage.NLCard.template}${StrapiApiEndPoints.nutritionLabel}`,
    isStrapiApi: true,
  });
});

const NLSlice = createSlice({
  name: "nutritionlabel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      NLContentStrapi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.nutritionLabelData = action.payload?.data?.data?.attributes;
        state.errorNutritionLabelData = null;
      }
    );
    builder.addCase(NLContentStrapi.rejected, (state, action) => {
      state.nutritionLabelData = null;
      state.errorNutritionLabelData = action.error || "Something went wrong";
    });
  },
});

export default NLSlice.reducer;
