import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Box, Badge, Grid, Container } from "@mui/material";
import { Menu, AccountCircle } from "@mui/icons-material";
import styles from "./S1_Header.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { HeaderToggleMenuItem } from "./HeaderToggleMenuItem";
import { HeaderNavigateItem } from "./HeaderNavigateItem";
import { HeaderModal } from "./HeaderModal";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { scroller } from "react-scroll";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import {
  GetCustomer,
  resetCustomerInfo,
} from "../../../features/checkout/checkoutSlice";
import { CustomerStatus, OperationInProcessState } from "../../../enums";
import { HeaderProps } from "./Header";
import {
  BrandNameUpdate,
  generateImageContainer,
  getResolutionDetails,
  gtmTagManager,
  shopWareRedirectionHandler,
} from "../../../utils";
import ENVConfig from "../../../config/env.config.json";

const HeaderComponent = ({
  cacheHeader,
  cacheCustomHeader,
  logoutHandler,
}: HeaderProps) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isRenderLogin, getCustomer } = useAppSelector(
    (state: any) => state.checkout
  );
  const { getCartItemsSuccess, addCartItemsSuccess } = useAppSelector(
    (state: any) => state.shopWare
  );
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const [isButtonToggeled, setIsButtonToggeled] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<any>(null);
  const [primaryHeaders, setPrimaryHeaders] = useState([]);
  const [secondaryHeaders, setSecondaryHeaders] = useState([]);
  const [loginItem, setLoginItem] = useState();
  const [cartItemsCount, setCartItemsCountFromApi] = useState(0);

  useEffect(() => {
    let searchParams = new URLSearchParams(document.location.search);
    let logoutCheck: string | null = searchParams.get("logout");
    if (logoutCheck) {
      logoutUser();
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    return () => {
      setDisplayModal(false);
    };
  }, [location]);

  useEffect(() => {
    getAuthData();
  }, [isRenderLogin]);

  let customer: any = localStorage.getItem("customerDetail");
  customer = customer ? JSON.parse(customer) : null;

  useEffect(() => {
    getHeaders();
  }, [location, cacheHeader, cacheCustomHeader, getCustomer]);

  useEffect(() => {
    if (getCartItemsSuccess?.lineItems) {
      cartCountHandlerShopware(getCartItemsSuccess);
    }
  }, [getCartItemsSuccess]);

  useEffect(() => {
    if (addCartItemsSuccess?.lineItems) {
      cartCountHandlerShopware(addCartItemsSuccess);
    }
  }, [addCartItemsSuccess]);

  const cartCountHandlerShopware = (cartData: any) => {
    let quantity = 0;
    cartData.lineItems.map((item: any) => {
      if (item.payload.planName) {
        quantity = quantity + parseInt(item?.payload?.quantity);
      } else {
        quantity = quantity + parseInt(item?.quantity);
      }
    });
    setCartItemsCountFromApi(quantity);
  };

  const logoutUser = () => {
    localStorage.setItem("globalValues", JSON.stringify(globalVal));
    setAuthUser(null);
    dispatch(resetCustomerInfo()).then((response) => {
      if (response.payload) {
        logoutHandler(RoutingURLs.home);
      }
    });
  };

  const renderHeaderItemContent = (
    item: any,
    index: number,
    showActiveIndicator: boolean = true,
    itemName?: string
  ) => {
    if (item?.subMenu && item?.subMenu.length > 0) {
      const headerItemName = item?.itemName || itemName?.trim()?.split(" ")[0];
      const menuActionItems = item?.subMenu.map((subItem: any) => ({
        label: subItem?.itemName,
        action: () => {
          subItem?.is_gaEventReqd &&
            gtmTagManager({
              event: subItem?.ga_tagName,
              click_section: "header",
            });
          setAnchorEl(null);
          setIsButtonToggeled("");
          handleNavigation(subItem);
          if (subItem.itemName.toLowerCase() === "logout") {
            localStorage.clear();
            localStorage.setItem("globalValues", JSON.stringify(globalVal));
            setAuthUser(null);
            dispatch(resetCustomerInfo()).then((response) => {
              if (response.payload) {
                logoutHandler(RoutingURLs.home);
              }
            });
          }
        },
        navigationPath: BrandNameUpdate(subItem.navigationPath),
      }));
      return (
        <HeaderToggleMenuItem
          key={`header-item-${index}`}
          itemName={headerItemName}
          isToggeledItem={headerItemName === isButtonToggeled}
          anchorEl={anchorEl}
          onRequestOpenMenu={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setIsButtonToggeled(headerItemName);
          }}
          menuItems={menuActionItems}
          closeMenu={() => {
            setAnchorEl(null);
            setIsButtonToggeled("");
          }}
          buttonId={`btn-key${index}`}
          menuId={`menu-key${index}`}
          headerStyle={itemName ? { color: "var(--text_color)" } : null}
          showArrowIcon={item?.itemName || itemName ? true : false}
          showActiveIndicator={!itemName}
        />
      );
    } else {
      return (
        <HeaderNavigateItem
          onClickItem={() => {
            item?.is_gaEventReqd &&
              gtmTagManager({
                event: item?.ga_tagName,
                click_section: "header",
              });
            if (
              (customer?.operationsInProcess?.includes(
                OperationInProcessState.paymentCallback
              ) ||
                !customer?.simPaid) &&
              item.navigationPath === RoutingURLs.activation
            ) {
              return;
            } else {
              handleNavigation(item);
            }
          }}
          showActiveIndicator={
            showActiveIndicator &&
            window.location.pathname === BrandNameUpdate(item?.navigationPath)
          }
          key={`header-item-${index}`}
        >
          {ENVConfig?.IS_SHOPWARE_ENABLED &&
          item?.itemName?.toLowerCase() === "cart" ? (
            <Badge
              badgeContent={cartItemsCount ?? ""}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "var(--primary_brand_color)",
                  color: "var(--white)",
                },
              }}
            >
              <Box
                component="img"
                alt={
                  cacheHeader?.cart_logo?.data?.attributes?.alternativeText ||
                  ""
                }
                src={cacheHeader?.cart_logo?.data?.attributes?.url || ""}
                width={"20px"}
                sx={{ justifyContent: "center" }}
              />
            </Badge>
          ) : (
            item?.itemName
          )}
        </HeaderNavigateItem>
      );
    }
  };
  const handleNavigation = (item: any) => {
    if (item?.isNewWindow) {
      window.open(BrandNameUpdate(item?.navigationPath), "_blank");
    } else {
      if (item?.itemName?.toLowerCase() === "plans") {
        navigate(BrandNameUpdate(item?.navigationPath));
        setTimeout(function () {
          scroller.scrollTo("bestCellPhonePlan", {
            duration: 1000,
            delay: 0,
            offset: -100,
            smooth: true,
          });
        }, 1000);
      } else {
        if (ENVConfig?.IS_SHOPWARE_ENABLED) {
          let itemName = item?.itemName?.toLowerCase();
          switch (itemName) {
            case "phones":
              shopWareRedirectionHandler(ENVConfig?.SHOP_DOMAIN, "home");
              break;
            case "buy a phone":
              shopWareRedirectionHandler(ENVConfig?.SHOP_DOMAIN, "home");
              break;
            case "cart":
              shopWareRedirectionHandler(ENVConfig?.SHOP_DOMAIN, "cart");
              break;
            case "wishlist":
              shopWareRedirectionHandler(ENVConfig?.SHOP_DOMAIN, "wishlist");
              break;
            case "my orders":
              shopWareRedirectionHandler(
                ENVConfig?.SHOP_DOMAIN,
                "orderhistory"
              );
              break;
            case "notifications":
              shopWareRedirectionHandler(
                ENVConfig?.SHOP_DOMAIN,
                "notifications"
              );
              break;
            default:
              navigate(BrandNameUpdate(item?.navigationPath));
              break;
          }
        } else navigate(BrandNameUpdate(item?.navigationPath));
      }
    }
  };

  const showHeaderModal = () => {
    setDisplayModal(true);
  };
  const hideHeaderModal = () => {
    setDisplayModal(false);
  };
  const getAuthData = () => {
    const auth = getAuth();
    /**
     * The onAuthStateChanged function will be executed when firebase auth gets changed
     * @param1 auth - Firebase configuration
     * @param2 callback function - @param1 user - logged user data
     */
    const susbscribeAuthUser = onAuthStateChanged(auth, (user: any) => {
      let searchParams = new URLSearchParams(document.location.search);
      let logoutCheck: string | null = searchParams.get("logout");
      if (!logoutCheck) {
        if (user?.displayName && user?.email) {
          setAuthUser(user);
          dispatch(GetCustomer({ username: encodeURIComponent(user.email) }));
        }
      }
      if (user?.isAnonymous || (!authUser?.displayName && !user?.displayName)) {
        localStorage.removeItem("customerDetail");
      }
    });
    susbscribeAuthUser();
  };
  const isCheckoutHeader = location.pathname === RoutingURLs.checkout;
  const isMyAccountPage = location.pathname === RoutingURLs.myAccount;
  const isTrackOrderPage =
    location.pathname === RoutingURLs.trackOrder ||
    location.pathname === RoutingURLs.activateEsim;
  const isActivationPage = location.pathname === RoutingURLs.activation;
  const isSecondaryloginPage = location.pathname === RoutingURLs.secondarylogin;
  const isActiationSteps = location.pathname === RoutingURLs.steps;
  const isWalletPage = location.pathname === RoutingURLs.wallet;
  const isUlPromoPage = location.pathname === RoutingURLs.ulPromo;
  const { mobile_logo, web_logo, tab_logo } = cacheHeader || {};
  let currentUser: any = JSON.parse(
    localStorage.getItem("currentuser") ||
      localStorage.getItem("ssoCustomerDetails") ||
      "null"
  );
  const getHeaders = () => {
    let customer: any = localStorage.getItem("customerDetail");
    customer = customer ? JSON.parse(customer) : null;
    let loginHeaderItem = null,
      primaryItems = [],
      secondaryItems = [];
    if (isCheckoutHeader || isSecondaryloginPage || isActiationSteps) {
      loginHeaderItem = cacheHeader?.c_login_item;
    } else if (
      isTrackOrderPage &&
      currentUser &&
      customer?.additionalLines === 0
    ) {
      primaryItems = cacheCustomHeader?.to_primary_items;
      secondaryItems = cacheCustomHeader?.to_secondary_items;
      loginHeaderItem = cacheCustomHeader?.to_login_item;
    } else if (
      isTrackOrderPage &&
      currentUser &&
      customer?.additionalLines > 0
    ) {
      primaryItems = cacheHeader?.s_u_t_p_i;
      secondaryItems = cacheHeader?.s_u_t_s_i;
      loginHeaderItem = cacheHeader?.s_u_t_l_i;
    } else if (isMyAccountPage) {
      primaryItems = cacheCustomHeader?.pra_primary_items;
      secondaryItems = cacheCustomHeader?.pra_secondary_items;
      loginHeaderItem = cacheCustomHeader?.pra_login_item;
    } else if (
      isActivationPage &&
      currentUser &&
      customer?.additionalLines === 0
    ) {
      primaryItems = cacheHeader?.activation_primary_items;
      secondaryItems = cacheHeader?.activation_secondary_items;
      loginHeaderItem = cacheHeader?.activation_logout_item;
    } else if (
      isActivationPage &&
      currentUser &&
      customer?.additionalLines > 0
    ) {
      primaryItems = cacheHeader?.s_u_a_p_i;
      secondaryItems = cacheHeader?.s_u_a_s_i;
      loginHeaderItem = cacheHeader?.s_u_a_l_i;
    } else if (isWalletPage) {
      loginHeaderItem = cacheHeader?.w_logout_item;
    } else if (isUlPromoPage) {
      loginHeaderItem = cacheHeader?.c_login_item;
      primaryItems = [];
      secondaryItems = [];
    } else if (
      currentUser &&
      customer?.isPrimary === false &&
      customer?.status === CustomerStatus.ACTIVE
    ) {
      primaryItems = cacheCustomHeader?.s_u_p_h_i;
      secondaryItems = cacheCustomHeader?.s_u_s_h_i;
      loginHeaderItem = cacheCustomHeader?.s_u_login_i;
    } else if (
      currentUser &&
      customer?.isPrimary === false &&
      (customer?.status === CustomerStatus.PORTING_FAILED ||
        customer?.status === CustomerStatus.ACTIVATION_FAILED)
    ) {
      primaryItems = cacheCustomHeader?.s_u_f_p_h_i;
      secondaryItems = cacheCustomHeader?.s_u_f_s_h_i;
      loginHeaderItem = cacheCustomHeader?.s_u_f_login_i;
    } else if (currentUser && customer?.status === CustomerStatus.ACTIVE) {
      primaryItems = cacheCustomHeader?.poa_primary_items;
      secondaryItems = cacheCustomHeader?.poa_secondary_items;
      loginHeaderItem = cacheCustomHeader?.poa_login_item;
    } else if (
      currentUser &&
      (customer?.status === CustomerStatus.PORTING_FAILED ||
        customer?.status === CustomerStatus.ACTIVATION_FAILED ||
        customer?.status === CustomerStatus.ACTIVATION_IN_PROGRESS ||
        customer?.status === CustomerStatus.PORTING_IN_PROGRESS)
    ) {
      primaryItems = cacheCustomHeader?.af_pf_primary_items;
      secondaryItems = cacheCustomHeader?.af_pf_secondary_items;
      loginHeaderItem = cacheCustomHeader?.af_pf_login_item;
    } else if (
      currentUser &&
      customer?.isPrimary === false &&
      (customer?.status === CustomerStatus.DISCONNECTED ||
        customer?.status === CustomerStatus.SUSPENDED)
    ) {
      primaryItems = cacheCustomHeader?.poad_primary_items;
      secondaryItems = cacheCustomHeader?.s_u_s_h_i;
      loginHeaderItem = cacheCustomHeader?.s_u_login_i;
    } else if (
      currentUser &&
      (customer?.status === CustomerStatus.DISCONNECTED ||
        customer?.status === CustomerStatus.SUSPENDED)
    ) {
      primaryItems = cacheCustomHeader?.poad_primary_items;
      secondaryItems = cacheCustomHeader?.poa_secondary_items;
      loginHeaderItem = cacheCustomHeader?.poad_login_item;
    } else if (currentUser && customer?.simPaid) {
      primaryItems = cacheCustomHeader?.pra_primary_items;
      loginHeaderItem = cacheCustomHeader?.pra_login_item;
      secondaryItems = cacheHeader?.pl_secondary_items;
    } else if (currentUser && customer && !customer?.simPaid) {
      primaryItems = cacheHeader?.public_primary_items;
      secondaryItems = cacheHeader?.public_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (!currentUser) {
      primaryItems = cacheHeader?.public_primary_items;
      secondaryItems = cacheHeader?.public_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (currentUser && !customer) {
      primaryItems = cacheHeader?.pl_primary_items;
      secondaryItems = cacheHeader?.pl_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    }
    setPrimaryHeaders(primaryItems);
    setSecondaryHeaders(secondaryItems);
    setLoginItem(loginHeaderItem);
  };
  const onLogoClickHandler = () => {
    if (
      currentUser &&
      (customer?.status === CustomerStatus.ACTIVE ||
        customer?.status === CustomerStatus.ACTIVATION_IN_PROGRESS ||
        customer?.status === CustomerStatus.PORTING_FAILED ||
        customer?.status === CustomerStatus.ACTIVATION_FAILED ||
        customer?.status === CustomerStatus.DISCONNECTED ||
        customer?.status === CustomerStatus.SUSPENDED)
    ) {
      navigate(RoutingURLs.postActivationHome);
    } else if (currentUser && customer?.simPaid) {
      if (customer?.esim) {
        navigate(RoutingURLs.activateEsim);
      } else if (customer?.isSimInHand) {
        if (ENVConfig?.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED) {
          navigate(RoutingURLs.deviceConfirmCheck);
        } else {
          navigate(RoutingURLs.activation);
        }
      } else {
        navigate(RoutingURLs.myAccount);
      }
    } else {
      navigate(RoutingURLs.home);
    }
  };
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  const customerName = authUser
    ? authUser?.displayName
    : currentUser && !ENVConfig.IS_SSO_LOGIN
    ? currentUser.displayName
    : ENVConfig.IS_SSO_LOGIN
    ? customer
      ? customer?.firstName
      : currentUser?.firstName
    : null;
  return (
    <div className={styles.Header_Wrapper}>
      <AppBar position="fixed" className={`${styles.Header_Container}`}>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          disableGutters
        >
          <Box pl={{ md: 3 }} sx={{ minWidth: "128px" }}>
            {(largeDesktop || desktop) &&
              generateImageContainer({
                data: web_logo,
                attrs: {
                  height: "45px",
                  width: "auto",
                },
                onClick: onLogoClickHandler,
                className: styles.web_logo,
                overrides: {
                  sx: {
                    cursor: "pointer",
                  },
                },
              })}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {isCheckoutHeader ? (
              <Box
                fontFamily={" var(--font_family_Bold)"}
                className={styles.header_txt}
              >
                {cacheHeader?.header1}
              </Box>
            ) : null}
            {isWalletPage ? (
              <Box className={styles.header_txt}>
                {cacheHeader?.wallet_header}
              </Box>
            ) : null}
            {primaryHeaders?.length > 0 &&
              primaryHeaders.map((headerItem: any, index: number) => {
                return renderHeaderItemContent(headerItem, index);
              })}
          </Box>
          <Box
            className={styles.secondary_header}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {secondaryHeaders?.length > 0 &&
              secondaryHeaders.map((headerItem: any, index: number) =>
                renderHeaderItemContent(headerItem, index, true)
              )}
            {customerName ? (
              <Box display="flex" alignItems="center" mr="15px">
                <AccountCircle
                  style={{ color: "var(--primary_brand_color)" }}
                />
                {renderHeaderItemContent(loginItem, 1, false, customerName)}
              </Box>
            ) : !isCheckoutHeader &&
              !isUlPromoPage &&
              cacheHeader?.nav_link1 ? (
              <HeaderNavigateItem
                onClickItem={() => {
                  handleNavigation(cacheHeader.nav_link1);
                }}
                showActiveIndicator={false}
              >
                {cacheHeader?.nav_link1?.itemName}
              </HeaderNavigateItem>
            ) : isCheckoutHeader && !customerName ? (
              <Box sx={{ width: "105px", padding: "15px 0 15px 0" }}>
                &nbsp;
              </Box>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>
      <AppBar position="fixed" className={styles.d_view_port}>
        {ENVConfig?.IS_SHOPWARE_ENABLED ? (
          <Toolbar disableGutters sx={{ minHeight: "71px" }}>
            <Grid container m={0}>
              <Grid item xs={9} display="flex" alignItems="center">
                <Box>
                  {ipad &&
                    generateImageContainer({
                      data: tab_logo,
                      attrs: {
                        height: "35px",
                        width: "132px",
                      },
                      onClick: onLogoClickHandler,
                      className: styles.tab_header_logo,
                    })}
                  {mobile &&
                    generateImageContainer({
                      data: mobile_logo,
                      attrs: {
                        height: "35px",
                        width: "132px",
                      },
                      onClick: onLogoClickHandler,
                      className: styles.mobile_header_logo,
                    })}
                </Box>
              </Grid>
              <Grid item xs={3} display="flex" alignItems={"center"}>
                {ENVConfig?.IS_SHOPWARE_ENABLED && (
                  <>
                    {!isCheckoutHeader && (
                      <Badge
                        badgeContent={cartItemsCount ?? ""}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: "var(--primary_brand_color)",
                            color: "var(--white)",
                            position: "absolute",
                            top: "22px",
                            right: "10px",
                          },
                          paddingRight: "10px",
                        }}
                      >
                        <Box
                          component="img"
                          alt={
                            cacheHeader?.cart_logo?.data?.attributes
                              ?.alternativeText || ""
                          }
                          src={
                            cacheHeader?.cart_logo?.data?.attributes?.url || ""
                          }
                          width={"19px"}
                          height={"20px"}
                          onClick={() =>
                            handleNavigation(cacheHeader.pl_secondary_items[0])
                          }
                        />
                      </Badge>
                    )}
                    {/* {customerName ? (
                      <Box>
                        {renderHeaderItemContent(
                          loginItem,
                          1,
                          false,
                          customerName
                        )}
                      </Box>
                    ) : (
                      <>
                        <HeaderNavigateItem
                          onClickItem={() => {
                            handleNavigation(cacheHeader.nav_link1);
                          }}
                          showActiveIndicator={false}
                        >
                          {cacheHeader?.nav_link1?.itemName}
                        </HeaderNavigateItem>
                      </>
                    )} */}
                  </>
                )}
                <Menu className={styles.menu_icon} onClick={showHeaderModal} />
              </Grid>
            </Grid>
          </Toolbar>
        ) : (
          <Toolbar disableGutters>
            <Box>
              <Menu className={styles.menu_icon} onClick={showHeaderModal} />
            </Box>
            <Box pl={1.2}>
              {ipad &&
                generateImageContainer({
                  data: tab_logo,
                  attrs: {
                    height: "35px",
                    width: "132px",
                  },
                  onClick: onLogoClickHandler,
                  className: styles.tab_header_logo,
                })}
              {mobile &&
                generateImageContainer({
                  data: mobile_logo,
                  attrs: {
                    height: "35px",
                    width: "132px",
                  },
                  onClick: onLogoClickHandler,
                  className: styles.mobile_header_logo,
                })}
            </Box>
          </Toolbar>
        )}
      </AppBar>
      <HeaderModal
        display={displayModal}
        onClose={hideHeaderModal}
        headerContent={cacheHeader}
        handleNavigation={handleNavigation}
        authUser={authUser}
        primaryHeaders={primaryHeaders}
        secondaryHeaders={secondaryHeaders}
        loginItem={loginItem}
        isCheckoutHeader={isCheckoutHeader}
        logoutHandler={logoutHandler}
        setAuthUser={setAuthUser}
      />
    </div>
  );
};

export default HeaderComponent;
