import { Box, Divider } from "@mui/material";
import { SelectCust, TextFieldCust } from "../../widgets";
import styles from "./S1_AddressInfo.module.scss";
import { AddressField } from "../AddressField/AddressField";
import { PaymentButtons } from "../PaymentButtons/PaymentButtons";
import { AddressFieldProps } from "../AddressInfo/AddressInfo";
import { SwitchCust } from "../../";
import { geocodeByAddress } from "react-places-autocomplete";
import { addressType, SimTypes } from "../../../features/checkout/Types";
import { displayPhoneNumberFormat } from "../../../utils/commonFunctions/ReusableFunctions";
import { PhoneNoPattern } from "../../../utils/regexPatterns";
import { SignUpAndLogin } from "../SignUpAndLogin";
import { countryList } from "../../../config/USAStateList";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateFormValues } from "../../../features/checkout/checkoutSlice";
import Config from "../../../config/env.config.json";
import AutoPayCard from "../AutoPayCard/AutoPayCard";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import SimInHand from "../SimInHandCard/SimInHand";

const T1_AddressInfo = (props: AddressFieldProps) => {
  const {
    formik,
    isLoggedIn,
    plan,
    line,
    setUserDetails,
    content,
    simPreference,
    setSimPreference,
    inputRefs,
    hideBackButton,
  } = props;
  const dispatch = useAppDispatch();
  const { firstName, lastName, phone, isShippingSameAsBilling } = formik.values;
  const { isSIMVerificationSuccess } = useAppSelector(
    (state: any) => state.checkout
  );
  const currentUser = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const handleShippingAddress = (value: any, name: string) => {
    formik.setFieldValue(`shippingAddress.${name}`, value);
  };
  const handleBillingAddress = (value: any, name: string) => {
    formik.setFieldValue(`billingAddress.${name}`, value);
  };
  const handleAddressSearchSelection = async (
    address: any,
    addressObj: "shippingAddress" | "billingAddress"
  ) => {
    const results = await geocodeByAddress(address);
    let city: string = "",
      state: string = "",
      zip: string = "",
      country: string = "",
      route: string = "",
      streetNumber: string = "";
    results &&
      results[0] &&
      results[0].address_components &&
      results[0].address_components.map((el: any) => {
        state = el.types.includes("administrative_area_level_1")
          ? el.short_name
          : state;
        zip = el.types.includes("postal_code") ? el.short_name : zip;
        city = el.types.includes("locality")
          ? el.long_name
          : el.types.includes("sublocality")
          ? el.long_name
          : el.types.includes("neighborhood")
          ? el.long_name
          : city;
        streetNumber = el.types.includes("street_number")
          ? el.short_name
          : streetNumber;
        route = el.types.includes("route") ? el.long_name : route;
        country = el.types.includes("country") ? el.short_name : country;
        return null;
      });
    const addressData = {
      address1: `${streetNumber} ${route}`,
      city: city,
      state: state,
      zip: zip,
      country: countryList?.[0]?.value,
    };
    if (country === "US") {
      formik.setFieldValue(
        `${addressObj}.address1`,
        `${streetNumber} ${route}`
      );
      formik.setFieldValue(`${addressObj}.city`, city);
      formik.setFieldValue(`${addressObj}.state`, state);
      formik.setFieldValue(`${addressObj}.zip`, zip);
      formik.setFieldValue(`${addressObj}.country`, "USA");
      formik.setTouched({
        ...formik.touched,
        [addressObj]: {
          ...formik.touched[addressObj],
          city: true,
          state: true,
          zip: true,
          country: true,
        },
      });
      if (formik.values.isShippingSameAsBilling) {
        formik.setFieldValue("shippingAddress", {
          ...addressData,
          type: addressType.shipping,
        });
        formik.setTouched({
          ...formik.touched,
          shippingAddress: {
            ...formik.touched["shippingAddress"],
            city: true,
            state: true,
            zip: true,
            country: true,
          },
        });
      }
    }
    let addresses = {
      [addressObj]: {
        ...addressData,
        type:
          addressObj === "billingAddress"
            ? addressType.billing
            : addressType.shipping,
      },
    };
    if (formik.values.isShippingSameAsBilling) {
      addresses = {
        billingAddress: {
          ...addressData,
          type: addressType.billing,
        },
        shippingAddress: {
          ...addressData,
          type: addressType.shipping,
        },
      };
    }

    dispatch(
      updateFormValues({
        ...addresses,
      })
    );
  };

  const updateFieldsState = (
    e: any,
    addressObjName: "shippingAddress" | "billingAddress"
  ) => {
    dispatch(
      updateFormValues({
        [addressObjName]: {
          ...formik.values[addressObjName],
          [e.target.name]: e.target.value,
        },
      })
    );
  };
  const {
    left_sec_heading,
    email_id_text,
    line_checkout,
    text,
    same_billing_address,
    radio_option_note,
    shipping_options,
    note_head,
    note_div,
    checkout_left,
    bill_content_subtext,
    billing_content,
    bill_content_title,
    text_fields_main,
    text_fields_div,
    account_labels,
  } = styles;
  let simPreferenceStore: any = JSON.parse(
    localStorage.getItem("simPreference") || "[]"
  );
  const alleSimCompatible: boolean = simPreferenceStore.every(
    (sim: any) => sim?.value === SimTypes?.eSim
  );
  const totalNumberOfPhysicalSims: number = simPreferenceStore.filter(
    (sim: any) => sim?.value === SimTypes?.sim
  ).length;

  return (
    <Box className={checkout_left}>
      {!isLoggedIn ? (
        <SignUpAndLogin
          plan={plan}
          line={line}
          setUserDetails={setUserDetails}
        />
      ) : (
        <>
          {Config.IS_SHOW_EMAIL_IN_CHECKOUT ? (
            <>
              <Box className={left_sec_heading}>
                {content?.GreenMan_img?.data?.attributes?.url ? (
                  <Box
                    component={"img"}
                    height="34px"
                    width="34px"
                    alt={
                      content?.GreenMan_img?.data?.attributes?.alternativeText
                    }
                    src={content?.GreenMan_img?.data?.attributes?.url}
                  />
                ) : null}
                <Box className={email_id_text}>
                  {props.user && props.user.user && props.user.user.email
                    ? props.user.user.email
                    : props.user && props.user.email
                    ? props.user.email
                    : props.user && props.user.username
                    ? props.user.username
                    : ""}
                </Box>
              </Box>
              <Divider sx={{ mb: "10px" }} />
            </>
          ) : null}
          {/* {content?.title && (
            <Box className={left_sec_heading}>
              <Box
                component={"img"}
                height="26px"
                width="21px"
                alt={content?.Location_img?.data?.attributes?.alternativeText}
                src={content?.Location_img?.data?.attributes?.url}
              />
              <Box>{content?.title}</Box>
            </Box>
          )} */}
          {/* {content?.title && <Box className={line_checkout}></Box>} */}
          <Box className={billing_content}>
            {content?.n_label && (
              <Box className={bill_content_title}>{content?.n_label}</Box>
            )}
            <Box
              sx={{
                paddingTop: "10px",
              }}
            >
              <Box className={text_fields_main}>
                <Box className={text_fields_div}>
                  {/* {content?.fn_label && (
                    <Box className={account_labels}>{content?.fn_label}</Box>
                  )} */}
                  <TextFieldCust
                    value={firstName}
                    name="firstName"
                    inputRef={inputRefs?.firstName}
                    label={content.fn_ph}
                    onChange={(e: any) => {
                      if (
                        /^[a-zA-Z]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched?.firstName && formik.errors?.firstName
                    }
                    error={
                      formik.touched?.firstName && formik.errors?.firstName
                        ? true
                        : false
                    }
                  />
                </Box>
                <Box className={text_fields_div}>
                  {/* {content?.lname_label && (
                    <Box className={account_labels}>{content?.lname_label}</Box>
                  )} */}
                  <TextFieldCust
                    value={lastName}
                    name="lastName"
                    inputRef={inputRefs?.lastName}
                    label={content?.ln_ph}
                    onChange={(e: any) => {
                      if (
                        /^[a-zA-Z]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched?.lastName && formik.errors?.lastName
                    }
                    error={
                      formik.touched?.lastName && formik.errors?.lastName
                        ? true
                        : false
                    }
                  />
                </Box>
              </Box>
              <Box className={text_fields_main}>
                <Box className={text_fields_div}>
                  {/* {content?.phone_label && (
                    <Box className={account_labels}>{content?.phone_label}</Box>
                  )} */}
                  <TextFieldCust
                    value={phone ? displayPhoneNumberFormat(phone) : ""}
                    name="phone"
                    inputRef={inputRefs?.phone}
                    maxlength={10}
                    label={content?.phone_ph}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (
                        (PhoneNoPattern.test(value) && Number(value) >= 0) ||
                        value === ""
                      ) {
                        if (value.length === 10) {
                          e.target.value = value
                            .replace(/\D+/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                        }
                        formik.handleChange(e);
                      }
                      if (value.length > 10) {
                        const contactNumber: string = String(
                          value.match(/\d/g)?.join("") || ""
                        );
                        e.target.value = contactNumber;
                        formik.handleChange(e);
                      }
                    }}
                    helperText={formik.touched?.phone && formik.errors?.phone}
                    error={
                      formik.touched?.phone && formik.errors?.phone
                        ? true
                        : false
                    }
                    onBlur={(e) =>
                      dispatch(updateFormValues({ phone: e.target.value }))
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ px: { xs: "10px", sm: "35px" } }}
            className={bill_content_title}
          >
            {content?.add1_title}&nbsp;
            {isShippingSameAsBilling && (
              <span className={bill_content_subtext}>{content?.add1_hint}</span>
            )}
          </Box>
          <AddressField
            content={content?.billing}
            formik={formik}
            inputRefs={inputRefs?.billingAddress}
            handleSelect={(e: any) =>
              handleAddressSearchSelection(e, "billingAddress")
            }
            handleAddressChange={handleBillingAddress}
            addressObjName="billingAddress"
            onBlurCallback={updateFieldsState}
          />
          {Config.IS_ESIM_SUPPORTED && alleSimCompatible ? null : (
            <>
              {content?.note_txt ? (
                <Box className={note_div}>
                  <Box component={"span"} className={note_head}>
                    {content?.note_label}
                  </Box>{" "}
                  {content?.note_txt}
                </Box>
              ) : null}
              <Box
                sx={{ padding: { xs: "10px", sm: "0px 35px" } }}
                className={
                  (Config?.IS_SIM_IN_HAND_ENABLED &&
                    props.simInHand &&
                    isSIMVerificationSuccess) ||
                  (props.simInHand &&
                    currentUser?.simId &&
                    currentUser?.isSimInHand)
                    ? styles.disable_section
                    : ""
                }
              >
                <Box className={shipping_options}>
                  {content?.shipment_lbl && (
                    <Box className={bill_content_title}>
                      {content?.shipment_lbl}
                    </Box>
                  )}
                  <SelectCust
                    value={formik.values.shipmentPlan}
                    name="shipmentPlan"
                    options={props.sendSimOptions}
                    placeholder={content?.shipment_pl}
                    formStyles={{ width: { xs: "100%", sm: "50%" } }}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        updateFormValues({ shipmentPlan: e.target.value })
                      );
                    }}
                    error={
                      formik.touched?.shipmentPlan &&
                      formik.errors?.shipmentPlan
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched?.shipmentPlan &&
                      formik.errors?.shipmentPlan
                    }
                  />
                </Box>
              </Box>
              {content?.shipping_n_txt ? (
                <Box className={radio_option_note}>
                  <Box className={note_div}>
                    <Box component={"span"} className={note_head}>
                      {content?.shipping_n_l}
                    </Box>{" "}
                    {BrandNameUpdate(content?.shipping_n_txt)}
                  </Box>
                </Box>
              ) : null}
              <Box
                className={
                  (Config?.IS_SIM_IN_HAND_ENABLED &&
                    props.simInHand &&
                    isSIMVerificationSuccess) ||
                  (props.simInHand &&
                    currentUser?.simId &&
                    currentUser?.isSimInHand)
                    ? styles.disable_section
                    : ""
                }
                sx={{ padding: { xs: "10px", sm: "0 35px" } }}
              >
                <Box className={same_billing_address}>
                  <SwitchCust
                    styles={{ mr: "0px" }}
                    checked={isShippingSameAsBilling}
                    handleSwitchToggle={(e: any) => {
                      let updatedAddressData: any = {
                        billingAddress: formik.values.billingAddress,
                        isShippingSameAsBilling: e.target.checked,
                      };
                      formik.setFieldValue(
                        "isShippingSameAsBilling",
                        e.target.checked
                      );
                      if (e.target.checked) {
                        updatedAddressData = {
                          ...updatedAddressData,
                          shippingAddress: {
                            ...formik.values.billingAddress,
                            type: addressType.shipping,
                          },
                        };
                        formik.setFieldValue("shippingAddress", {
                          ...formik.values.billingAddress,
                          type: addressType.shipping,
                        });
                      }
                      dispatch(
                        updateFormValues({
                          ...formik.values,
                          ...updatedAddressData,
                        })
                      );
                    }}
                    name="isShippingSameAsBilling"
                  />
                  <Box className={text}>{content?.add_check_l}</Box>
                </Box>
                {!isShippingSameAsBilling && (
                  <Box>
                    <Box className={bill_content_title}>
                      {content?.add2_title}{" "}
                      <span className={bill_content_subtext}>
                        {content?.add2_hint}
                      </span>
                    </Box>
                    <AddressField
                      content={content?.shipping}
                      formik={formik}
                      handleSelect={(e: any) =>
                        handleAddressSearchSelection(e, "shippingAddress")
                      }
                      handleAddressChange={handleShippingAddress}
                      addressObjName="shippingAddress"
                      onBlurCallback={updateFieldsState}
                      inputRefs={inputRefs?.shippingAddress}
                    />
                  </Box>
                )}
              </Box>
            </>
          )}
          {Config.IS_ESIM_SUPPORTED &&
          totalNumberOfPhysicalSims > 0 &&
          content?.note_list_i?.length > 0 ? (
            <Box className={note_div}>
              <Box
                component={"span"}
                sx={{ color: "var(--note_danger)", marginBottom: "0.5rem" }}
              >
                {content?.note_label}
              </Box>
              <br />
              {content?.note_list_i.map((el: any) => (
                <Box
                  component={"span"}
                  sx={{
                    fontSize: "13px",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                  key={el?.id}
                >
                  {el?.item}
                </Box>
              ))}
            </Box>
          ) : null}
          {Config?.IS_SIM_IN_HAND_ENABLED &&
          simPreferenceStore?.[0]?.value !== SimTypes?.eSim ? (
            <>
              <Box className={line_checkout}></Box>
              <SimInHand
                simId={props.simId}
                simInHand={props.simInHand}
                setSimInHand={props.setSimInHand}
                setSimId={props.setSimId}
                onSubmitSimHandler={props.onSubmitSimHandler}
                simInHandContentState={props.simInHandContentState}
              />
            </>
          ) : null}
          {Config?.IS_AUTOPAY_ENABLED ? (
            <Box
              className={
                (Config?.IS_SIM_IN_HAND_ENABLED &&
                  props.simInHand &&
                  isSIMVerificationSuccess) ||
                (props.simInHand &&
                  currentUser?.simId &&
                  currentUser?.isSimInHand)
                  ? styles.disable_section
                  : ""
              }
            >
              <AutoPayCard
                isAutoPay={props.isAutoPay}
                isSaveCard={props.isSaveCard}
                onChangeAutoPayment={props.onChangeAutoPayment}
                onChangeSaveCard={props.onChangeSaveCard}
                autoPayContentState={props.autoPayContentState}
                containerBorder={true}
                note={true}
              />
            </Box>
          ) : null}
          <PaymentButtons
            paymentClicked={props.paymentClicked}
            formik={formik.handleSubmit}
            navigate={props.navigate}
            hideBackButton={hideBackButton}
            content={{
              ...content,
              submit_btn:
                Config.IS_ESIM_SUPPORTED && alleSimCompatible
                  ? content?.add_card_btn
                  : (Config?.IS_SIM_IN_HAND_ENABLED &&
                      props.simInHand &&
                      isSIMVerificationSuccess &&
                      !Config.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND) ||
                    (props.simInHand &&
                      currentUser?.simId &&
                      currentUser?.isSimInHand &&
                      !Config.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND)
                  ? content?.activate_btn
                  : content?.submit_btn,
            }}
            disabled={
              props.paymentClicked ||
              (Config?.IS_SIM_IN_HAND_ENABLED &&
                props.simInHand &&
                !currentUser?.isSimInHand &&
                !isSIMVerificationSuccess)
            }
          />
        </>
      )}
    </Box>
  );
};

export default T1_AddressInfo;
