import { combineReducers } from "redux";
import homePageSlice from "./homePageSlice";
import bestFitSlice from "./bestFitSlice";
import componentSlice from "./componentSlice";
import discoverSlice from "./discoverSlice";
import aboutUsSlice from "./aboutUsSlice";
import contactUsSlice from "./contactUsSlice";
import reviewsSlice from "./reviewsSlice";
import serviceSlice from "./serviceSlice";
import termSlice from "./termSlice";
import privacySlice from "./privacySlice";
import giveBackSlice from "./giveBackslice";
import primaryValuesSlice from "./primaryValuesSlice";
import signinSlice from "./signinSlice";
import coverageCheckSlice from "./coverageCheckSlice";
import imeiCompatibilitySlice from "./imeiCompatibilitySlice";
import ISPageSlice from "./ISPageSlice";
import postActivationHomeSlice from "./postActivationHomeSlice";
import postActivationAccountSlice from "./postActivationAccountSlice";
import billCalculatorSlice from "./billCalculatorSlice";
import helpSlice from "./helpSlice";
import profileSlice from "./profileSlice";
import signupSlice from "./signupSlice";
import activationSlice from "./activationSlice";
import trackOrderSlice from "./trackOrderSlice";
import billingSlice from "./billingSlice";
import checkoutSlice from "./checkoutSlice";
import MyAccountSlice from "./myAccountSlice";
import manageCardSlice from "./manageCardSlice";
import accountSlice from "./accountSlice";
import secondaryUserHomeSlice from "./secondaryUserHomeSlice";
import settingsSlice from "./settingsSlice";
import walletSlice from "./walletSlice";
import globalValuesSlice from "./globalValuesSlice";
import landingPageSlice from "./landingPageSlice";
import devcieConfirmCheckSlice from "./deviceConfirmSlice";
import paymentLandingSlice from "./paymentLandingSlice";
import nutritionLabelSlice from "./nutritionLabelSlice";
import accountLockSlice from "../account-lock/accountLockSlice";
import apnSettingsSlice from "./apnSettingsSlice";
import safeConnectionSlice from "./safeConnectionSlice";

const rootSlice = combineReducers({
  homepage: homePageSlice,
  bestfit: bestFitSlice,
  commonPage: componentSlice,
  aboutUs: aboutUsSlice,
  reviews: reviewsSlice,
  services: serviceSlice,
  terms: termSlice,
  privacy: privacySlice,
  giveBack: giveBackSlice,
  contactUsPage: contactUsSlice,
  PrimaryValues: primaryValuesSlice,
  signinPage: signinSlice,
  signupPage: signupSlice,
  coverageCheck: coverageCheckSlice,
  imeiCompatibilityPage: imeiCompatibilitySlice,
  ISPage: ISPageSlice,
  discover: discoverSlice,
  billCalculator: billCalculatorSlice,
  postActivation: postActivationHomeSlice,
  postActivationAccount: postActivationAccountSlice,
  help: helpSlice,
  profile: profileSlice,
  activation: activationSlice,
  trackOrder: trackOrderSlice,
  billing: billingSlice,
  checkout: checkoutSlice,
  myAccount: MyAccountSlice,
  manageCard: manageCardSlice,
  account: accountSlice,
  secondaryUserContent: secondaryUserHomeSlice,
  settings: settingsSlice,
  wallet: walletSlice,
  globalValues: globalValuesSlice,
  landingPage: landingPageSlice,
  deviceConfirmCheck: devcieConfirmCheckSlice,
  paymentLanding: paymentLandingSlice,
  nutritionLabel: nutritionLabelSlice,
  accountLockSlice: accountLockSlice,
  apnSettings: apnSettingsSlice,
  safeCon: safeConnectionSlice,
});

export default rootSlice;
