import { Typography, Box, Paper } from "@mui/material";
import { geocodeByAddress } from "react-places-autocomplete";
import { ButtonCust, SimpleModal, TextFieldCust } from "../../../../widgets";
import { object, string } from "yup";
import styles from "./AddShippingAddressDialog.module.scss";
import Config from "../../../../../config/env.config.json";
import { useFormik } from "formik";
import { AddressField } from "../../../../checkout/AddressField/AddressField";
import { namePattern } from "../../../../../utils/regexPatterns";
import { addressType } from "../../../../../features/checkout/Types";
import { useAppSelector } from "../../../../../app/hooks";

type Props = {
  open: boolean;
  onClose?: any;
  updateAddress: any;
  pageName?: any;
  content?: any;
};
export function AddShippingAddressDialogComp(props: Props) {
  const { open, onClose, updateAddress, pageName } = props;
  const { content } = props;
  const {
    add_address,
    fisrtname_ph,
    lastname_ph,
    firstname_err,
    lastname_err,
    address_ttl,
    save_btn_txt,
  } = props?.content;

  interface formikintitaltypes {
    firstName: string;
    lastName: string;
    billingAddress: any;
  }

  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const { firstName, lastName } = getCustomer || {};
  const initialformstep1data: formikintitaltypes = {
    firstName: firstName || "",
    lastName: lastName || "",
    billingAddress: {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.billing,
    },
  };
  const billingAddressVal = object({
    address1: string().trim().required(add_address?.address1_err),
    city: string().trim().required(add_address?.city_err),
    state: string().trim().required(add_address?.state_err),
    zip: string()
      .trim()
      .required(add_address?.zip_err1)
      .matches(/^[0-9]+$/, add_address?.zip_err2)
      .min(5, add_address?.zip_err3)
      .max(5, add_address?.zip_err3),
  });
  const shipmingFormSchema = object({
    firstName: string().trim().required(firstname_err),
    lastName: string().trim().required(lastname_err),
    billingAddress: billingAddressVal,
  });
  const shipmingFormSchema2 = object({
    billingAddress: billingAddressVal,
  });
  const formik: any = useFormik({
    initialValues: initialformstep1data,
    validationSchema:
      pageName !== "activation" ? shipmingFormSchema : shipmingFormSchema2,
    onSubmit: (values: any) => {
      updateAddress(formik.values);
    },
    enableReinitialize: true,
  });
  const handleBillingAddress = (value: any, name: string) => {
    formik.setFieldValue(`billingAddress.${name}`, value);
  };
  const handleAddressSearchSelection = async (
    address: any,
    addressObj: "billingAddress"
  ) => {
    const results = await geocodeByAddress(address);
    let city: string = "",
      state: string = "",
      zip: string = "",
      country: string = "",
      route: string = "",
      streetNumber: string = "";
    results &&
      results[0] &&
      results[0].address_components &&
      results[0].address_components.map((el: any) => {
        state = el.types.includes("administrative_area_level_1")
          ? el.short_name
          : state;
        zip = el.types.includes("postal_code") ? el.short_name : zip;
        city = el.types.includes("locality")
          ? el.long_name
          : el.types.includes("sublocality")
          ? el.long_name
          : el.types.includes("neighborhood")
          ? el.long_name
          : city;
        streetNumber = el.types.includes("street_number")
          ? el.short_name
          : streetNumber;
        route = el.types.includes("route") ? el.long_name : route;
        country = el.types.includes("country") ? el.short_name : country;
        return null;
      });
    if (country === "US") {
      formik.setFieldValue(
        `${addressObj}.address1`,
        `${streetNumber} ${route}`
      );
      formik.setFieldValue(`${addressObj}.city`, city);
      formik.setFieldValue(`${addressObj}.state`, state);
      formik.setFieldValue(`${addressObj}.zip`, zip);
      formik.setFieldValue(`${addressObj}.country`, "USA");
      const touchedBilling: any = formik.touched.billingAddress;
      formik.setTouched({
        ...formik.touched,
        billingAddress: {
          ...touchedBilling,
          city: "",
          state: "",
          zip: "",
        },
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "var(--lightgrey_3)",
        }}
      >
        <Paper
          sx={{
            // border: "1px solid rgba(0,0,0,.2)",
            width: { sm: "724px" },
            // height: "600px",
            my: { xs: "60px" },
            boxShadow: { xs: "unset !important" },
            // marginBottom:"100px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              py: { xs: "31px" },
            }}
          >
            <Box
              component="img"
              alt={"circular_loader"}
              src={content?.swap_shipping_icon?.data?.attributes?.url}
              sx={{ width: { xs: "33px", paddingRight: "16px" } }}
            />
            <Typography
              sx={{ fontSize: "24px", fontFamily: "var(--font_family_Bold)" }}
            >
              {content?.swap_shipping_title}
            </Typography>
          </Box>
          <hr style={{ border: "1px solid #F8F8F8" }}></hr>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "var(--font_family_Medium)",
                mt: { xs: "18px" },
                // mb: { xs: "21px" },
                px: { xs: "10px", sm: "120px" },
              }}
            >
              {content?.swap_shipping_content}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "var(--font_family_Medium)",
                mt: { xs: "18px" },
                mb: { xs: "21px" },
                px: { xs: "10px", sm: "120px" },
              }}
            >
              {content?.swap_shipping_content2}
            </Typography>
          </Box>
          <Box>
            {pageName !== "activation" && (
              <Box className={styles.text_fields_main}>
                <Box className={styles.text_fields_div}>
                  <TextFieldCust
                    value={formik.values.firstName}
                    name="firstName"
                    label={fisrtname_ph}
                    onChange={(e: any) => {
                      if (
                        namePattern.test(
                          e.target.value || e.target.value === ""
                        )
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched?.firstName && formik.errors?.firstName
                    }
                    error={
                      formik.touched?.firstName && formik.errors?.firstName
                        ? true
                        : false
                    }
                  />
                </Box>
                <Box className={styles.text_fields_div}>
                  <TextFieldCust
                    value={formik.values.lastName}
                    name="lastName"
                    label={lastname_ph}
                    onChange={(e: any) => {
                      if (
                        namePattern.test(
                          e.target.value || e.target.value === ""
                        )
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched?.lastName && formik.errors?.lastName
                    }
                    error={
                      formik.touched?.lastName && formik.errors?.lastName
                        ? true
                        : false
                    }
                  />
                </Box>
              </Box>
            )}
            <Box sx={{ padding: "20px" }}>
              <AddressField
                content={add_address}
                formik={formik}
                handleSelect={(e: any) =>
                  handleAddressSearchSelection(e, "billingAddress")
                }
                handleAddressChange={handleBillingAddress}
                addressObjName="billingAddress"
              />
            </Box>
          </Box>
          <Box
            textAlign="center"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <ButtonCust
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={() => formik.handleSubmit()}
            >
              {content?.swap_btn_txt}
            </ButtonCust>
          </Box>
          {/* <Box display="flex" flexWrap="wrap" pl="20px">
            <Box
              width={{ xs: "100%", sm: "80%", md: "45%" }}
              sx={{ px: { xs: "12px" } }}
            >
             <TextFieldCust
                  value={formik.values.firstName}
                  name="firstName"
                  label={fisrtname_ph}
                  onChange={(e: any) => {
                    if (
                      namePattern.test(e.target.value || e.target.value === "")
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  helperText={
                    formik.touched?.firstName && formik.errors?.firstName
                  }
                  error={
                    formik.touched?.firstName && formik.errors?.firstName
                      ? true
                      : false
                  }
                />
            </Box>
            </Box> */}
        </Paper>
      </Box>
    </>
  );
}
